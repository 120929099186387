@import "./colors.scss";

.coc-estimates-sortable-header.modified {
    width: 150px;
}
.all-actions {
    width: 210px;
}
.coc-estimates-select-option.share {
    color: $lightgray;
    background-color: inherit !important;
    cursor: not-allowed;
}
.coc-rename .clear {
    clear: both;
}