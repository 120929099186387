@import "./colors.scss";
.common-table-title {
  color: $krollblue;
  font-size: 18px;
  border-bottom: solid 1px $krollshadow;
  margin-bottom: 10px;
  font-weight: bold;
}

.common-table {
  margin-bottom: 20px;
}
.common-table thead {
  font-weight: bold;
  color: $skyblue;
  cursor: pointer;
}
.common-table td {
  min-width: 231px;
}
