.coe-summary-label {
    text-align: center;
    color: $skyblue;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0px;
}

.equation-wrapper {
    margin-top: 100px;
    height: 100%;
}

equation-wrapper:first .equation-wrapper {
    margin-top: 0px;
}

.equation-group {
    margin-bottom: 20px;
}

.equation-group .equation-title {
    margin-bottom: 10px;
    font-weight: bold;

    // color: $coolgray;
    // border-bottom: solid 1px $coolgray;
    a,
    a:hover {
        color: $krollgreen;
        padding: 0 10px;
        font-size: 0.8rem;
        cursor: pointer;
    }
}

.country-table {
    margin: 20px 100px;
}

.country-table .country-data {
    margin-bottom: 30px;
}

.equation-container {
    margin: 30px 0px;
}

.text-align-end {
    text-align: end;
}

.fisher-effect-main {
    text-align: center;
}

.fisher-effect {
    display: inline-flex;
    align-items: center;
}

a {
    text-decoration: none;
}