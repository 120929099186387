$blue: #13b5ea;
$fadedblue: #48c6ee;
$darkblue: #1a82ea;
$darkerblue: #2e5497;
$duff-light-blue: rgba(19, 181, 234, 1);
$darkgray: #465661;
$gray: #84939e;
$lightgray: #cccccc;
$white: #ffffff;
$red: #ee3124;
$fadedred: #f36d66;
$graywhite: #ecedef;
$whitegray: #f2f2f2;
$purple: #7b2379;
$fadedpurple: #954f95;
$orange: #f47621;
$darkorange: #bf550d;
$purewhite: #ffffff;
$RGB698596: rgb(69, 85, 96);
$krollblue: #14487f;
$krollgreen: #43b049;
$krollshadow: #4d4d4f;
$error: #e2665c;
$success: #0cb358;
$midnightblue: #001424;
$darkblue: #003057;
$skyblue: #4c9fc8;
$steelblue: #4f769f;
$coolgray: #a7a8a9;
$oceanblue: #67d2df;
$forestgreen: #215732;
$limegreen: #c4d600;
$magenta: #d00070;
$punch: #e2665c;
$thinlightgray: #ededed;
