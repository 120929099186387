@import "../../colors.scss";

summary {
    cursor: inherit;

    .all-results.resummarizing {
        z-index: -1;
        position: relative;
    }
    .coc-result-summary h2,
    .coc-result-details h2 {
        font-weight: bold;
        text-align: center;
    }
    .coc-result-summary h3 {
        color: $coolgray;
        font-size: 24px;
        margin: 12px 0 20px;
    }
    .coc-result-boxes .detail-box,
    .coc-result-boxes .average-box,
    .coc-result-boxes .graph-box,
    .coc-result-boxes .chart-box {
        background: $thinlightgray; // $light gray with opacity 0.3;
        padding: 8px 12px;
        margin: 0 10px 20px;
        height: 10vw;
        flex: 1 0 0;
        min-height: 120px;
        // outline: 2px solid #cccccc80;
    }
    .coc-result-boxes .detail-box,
    .coc-result-boxes .chart-box {
        display: block;
    }
    .coc-result-boxes .average-box,
    .coc-result-boxes .graph-box {
        display: flex;
    }
    .coc-result-boxes .graph-box {
        flex: 1.3 0 0;
    }
    .coc-result-boxes .detail-box .item {
        width: 25%;
        text-align: center;
        float: left;
        padding: 10px;
    }
    .coc-result-boxes .detail-box .percent {
        font-weight: bold;
        font-size: 1.1vw;
    }
    .coc-result-boxes .detail-box .percent-title {
        font-size: calc(8px + 0.1vw);
    }
    .coc-result-boxes .average-box {
        flex-direction: column;
        justify-content: center;
    }
    .coc-result-boxes .average-box .title,
    .coc-result-boxes .detail-box .title {
        text-align: center;
        font-size: 1.2vw;
        margin: 6px 0 10px;
    }
    .coc-result-boxes .average-box .value {
        font-size: 3vw;
        text-align: center;
        color: $krollblue;
        font-weight: bold;
    }
    .coc-results-divider {
        border-bottom: 1px solid $coolgray;
    }
    .coc-result-section {
        display: flex;
        flex-direction: row;
        .pane:first-child {
            margin-right: 30px;
        }
        .pane {
            display: flex;
            flex: 1 0 0;
            flex-direction: column;
        }
        .group {
            margin: 0 0 20px 0;
        }
        .group-title {
            color: $krollblue;
            font-weight: bold;
            font-size: 20px;
            margin: 0px 0px 20px 0;
            // border-bottom: 1px solid #D0D7DD;
        }
        .category-name {
            font-weight: bold;
        }
        .item {
            padding: 4px 0;
        }
        .item-name {
            color: $coolgray;
        }
        .item-value {
            font-weight: bold;
        }
    }
    .coc-result-sources {
        .group {
            margin: 0 0 20px 30px;
        }
        .category-title {
            font-size: 22px;
            text-decoration: underline;
            font-weight: bold;
            margin-bottom: 15px;
            margin-top: 30px;
        }
    }
    .coc-collapsible-title-bar > .collapsible {
        text-align: center;
        // margin: 0 -30px;
    }
    .coc-collapsible-title-bar > .collapsible .title {
        font-weight: bold;
        text-align: center;
        font-size: 26px;
        z-index: 50;
        display: inline-block;
        position: relative;
        padding: 0 20px;
        color: $skyblue;
    }
    .coc-collapsible-title-bar > .collapsible:before,
    .coc-collapsible-title-bar > .collapsible:after {
        content: " ";
        display: block;
        height: 1px;
        flex: 1 0 0;
        justify-content: center;
        background-color: $coolgray;
        align-content: center;
    }

    /* Report Studies */
    .coc-report-studies .study-group-title > .collapsible .title {
        font-weight: bold;
        font-size: 26px;
        color: $skyblue;
    }
    .coc-report-studies .study-title > .collapsible .title {
        font-weight: bold;
        font-size: 22px;
        padding: 0 0 0 40px;
        width: 100%;
        color: $skyblue;
    }
    .coc-report-studies .scenario-title > .collapsible .title {
        font-weight: bold;
        color: $skyblue;
    }
    .coc-report-studies .study-group-title > .collapsible .title i {
        font-size: 54px;
        margin: -20px 10px 0 0;
        position: relative;
        top: 8px;
    }
    .coc-report-studies .study-title > .collapsible .title i {
        font-size: 40px;
        margin: -12px 8px 0 0;
        position: relative;
        top: 6px;
    }
    .coc-report-studies .scenario-title > .collapsible .title i {
        font-size: 28px;
        margin: -6px 10px 10px 10px;
        position: relative;
        top: 4px;
    }
    .coc-report-studies .study-title > .collapsible .title:after {
        content: " ";
        display: block;
        border-bottom: 1px solid $coolgray;
        margin: 10px -30px 16px -30px;
    }
    .coc-report-studies .scenario {
        padding: 0 0 0 50px;
        border-bottom: 1px solid $coolgray;
        margin-bottom: 20px;
    }
    .coc-report-studies .equation {
        margin-left: 50px;
    }
    .coc-report-studies .equation-title {
        font-weight: bold;
        color: $coolgray;
        display: inline-block;
    }
    .coc-report-studies .equation-toggle {
        display: inline-block;
        color: $krollgreen;
        cursor: pointer;
        font-size: 15px;
        margin-left: 20px;
    }
    .coc-report-studies .scenario:last-child {
        margin-bottom: 50px;
    }

    .coc-report-studies .parameter {
        display: inline-block;
        text-align: center;
        color: $krollshadow;
        font-size: 2.4vw;
        font-weight: bold;
        vertical-align: top;
    }
    .coc-report-studies .parameter-title {
        font-size: 0.76vw;
        text-transform: uppercase;
    }
    .coc-report-studies .parameter:nth-child(1) {
        color: $krollblue;
    }
    .coc-report-studies .parameter:nth-child(odd) {
        width: 16%;
    }
    .coc-report-studies .parameter:nth-child(even) {
        width: 5%;
    }
    .coc-report-studies .excluded-equation {
        opacity: 0.25;
    }
    .fa.fa-angle-up,
    .fa.fa-angle-down {
        color: $skyblue;
    }
    @media screen and (min-width: 1280px) {
        //For Big TV's (HD Screens)
        .coc-report-studies .parameter {
            font-size: 2vw;
        }
    }

    @media screen and (min-width: 1920px) {
        //For Projectors or Higher Resolution Screens (Full HD)
        .coc-report-studies .parameter {
            font-size: 1.5vw;
        }
    }
    @media screen and (min-width: 3840px) {
        //For 4K Displays (Ultra HD)
        .coc-report-studies .parameter {
            font-size: 0.8vw;
        }
    }
}
