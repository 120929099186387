@import "./colors.scss";

.tear-sheet {
    font-size: 0.92rem;
    // min-width: 1370px;
}

.tear-sheet-content {
    padding: 16px;
}

.tear-sheet-unavailable-container {
    padding-top: 200px;
    padding-bottom: 200px;
}

.tear-sheet-unavailable-content {
    background-color: $krollblue;
    color: #FFFFFF;
    padding: 60px 120px;
    text-align: center;
}

.tear-sheet-unavailable-content a {
    font-weight: bold;
    text-decoration: underline;
}

.data-through-date {
    border-bottom: solid 3px $krollblue;
    margin-bottom: 10px;
}

.industry-data {
    display: inline-block;
    vertical-align: top;
    // width: 22%;
    padding-right: 50px;
}

.industry-statistics {
    display: inline-block;
    vertical-align: top;
    // width: 78%;
}

.sic-code {
    font-size: 2.8rem;
    font-weight: bold;
    color: $krollblue;
}

.sector {
    color: $krollblue;
    font-weight: bold;
}

.metric-category {
    margin-top: 2%;
    margin-right: 1%;
    display: inline-block;
    vertical-align: top;
}

.statistics-category {
    vertical-align: top;
    float: right;
}

.metric-category-title,
.statistics-category-title {
    color: $krollblue;
    font-weight: 700;
    font-size: 16px;
    margin: 20px 0 5px 0;
}

.metric-category-table {
    display: inline-block;
}

.metric,
.statistics {
    display: inline-block;
    margin-right: 20px;
    vertical-align: bottom;
}

.metric-category-table,
.composite-table,
.statistics-table {

    border-collapse: collapse;
    width: initial;

    th,
    td {
        text-align: initial;
        padding: initial;
    }

    tr {
        line-height: initial;
    }
}

.metric-row>metric-category:last-child>.metric-category {
    margin-right: 0%;
}

.metric-category>.composite-table>metric:last-child>.metric,
.statistics-category>statistics:last-child>.statistics {
    margin-right: 0px;
}

.composite-table,
.composite-chart {
    display: inline-block;
    vertical-align: top;
}

.metric-name {
    font-weight: bold;
    border-bottom: solid 1px;
}

.statistics-table>thead>tr,
.composite-table>thead>tr {
    height: 50px;
}

.industry-data-table {
    thead {
        td {
            padding: 5px;
            padding-left: 0px;
        }
    }
}

.statistics-table td,
.composite-table td {
    vertical-align: top;
}

.statistics-table td {
    width: 120px;
}

.composite-table td {
    width: 70px;
}

.statistics-table .statistics-table-label,
.composite-table .statistics-table-label {
    width: 180px;
}

.metric-table {
    display: inline-block;
}

.composite-labels {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 20px;
}

.tear-sheet-scrolling-bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.usi-navigation {
    float: right;
    display: none;
}

.usi-navigation.show {
    display: block;

    &:first-of-type>button div {
        text-align: left;
    }

    button div {
        font-size: 0.8rem;
    }
}

.usi-navigation button {
    background-color: #FFFFFF;
    border: none;
}

.usi-navigation button i {
    font-size: 3.5rem;
    margin: 0 26px 0 12px;
}

.usi-navigation button:not([disabled]) i {
    cursor: pointer;
    color: $krollblue;
}

.usi-navigation button[disabled] i {
    cursor: not-allowed;
    color: $coolgray;
}

.usi-industry {
    margin-top: -20px;
}

.usi-industry-link {
    color: $krollgreen;
    cursor: pointer;
    font-size: 16px;
    margin-inline: 15px;

}

.usi-Remove-Scenario {
    color: $krollgreen;
    cursor: pointer;
    font-size: 16px;
}

.industry-link {
    color: $krollgreen;
    cursor: pointer;
    font-size: 16px;
}

.usi-industry-note {
    color: #465661;
    font-size: 16px;
    text-align: right;
}

#note-heading {
    color: $skyblue;
}

.usi-industry-note a {
    color: $krollgreen;
}

.usi-industry-note a:hover {
    text-decoration: underline;
    color: $krollgreen
}

.company-table {
    margin-top: 30px;
    width: 100%;
}

.company-table-name {
    width: 40%;
}

.trends-over-time-link {
    color: $skyblue;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}

.trends-over-time-fishereffect {
    color: $skyblue;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.opentrends {
    color: $skyblue;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.trends-over-time-link-CR {
    color: $coolgray;
    cursor: text;
}

.fisher-effect {
    color: darkslategray;
}