@import "./colors.scss";

.riskTable {
    margin-left: 20px;
    color: $coolgray;
    font-size: 14px;
    cursor: pointer;

    i {
        position: relative;
        top: 3 px;
        padding-left: 10px;
        font-size: 22px;
    }
}

p-dialog.riskDialog {
    table {
        margin: 30px auto;
        width: 85%;

        thead {
            vertical-align: bottom;
        }

        th {
            padding: 0 25px 0 0;

            div {
                margin: 0 2px;
                border-bottom: 1px solid;
            }
        }
    }
}
