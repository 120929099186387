@import "./colors.scss";

.common-input-line {
    padding: 10px 15px;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.common-input-line-stack {
    text-align: left !important;
    display: initial !important;
    flex-direction: unset !important;
}

.common-input-name {
    flex: 1 0 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 5px;
    text-align: right;

}

.betaFont {
    .common-input-name {
        font-weight: bold !important;
        flex: 1.1 0 0 !important;
    }

    .common-input-container {
        width: 50%;
    }
}

.common-input-name-stack {
    display: unset !important;
    text-align: left !important;
    font-weight: bold;
}

.common-input-content {
    flex: 2 0 0;
    display: flex;
}

.common-input-content-stack {
    min-width: 80%;
    float: left;
}

.common-input-help {
    height: 22px;
    margin-top: 5px;
    margin-left: 30px;
    flex: 0.5 0 0;
    display: flex;
}

.common-input-help-stack {
    display: unset !important;
    float: right;
}

.common-input-error {
    text-align: center;
    color: $punch;
    margin-top: 10px;
}

.input-flex-display {
    display: flex;
    flex-direction: column;

    & .common-input-name-stack,
    & .common-input-help {
        position: relative;
        bottom: 10px;
    }

    & .common-help-icon i {
        top: 0;
        left: 0;
    }

    & .common-input-content-stack {
        min-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1440px) {
    .common-input-name {
        margin-right: 0px;
    }
}

@media screen and (max-width: 1440px) {
    .betaFont {
        .common-input-name {
            padding-right: 17px;
        }
    }
}

.supplcombomrgn .commoncss .common-input-line {
    margin-top: -22px !important;
}

.view-more-mt {
    width: 240px;

    .common-input-line {
        margin-top: -37px;

        .common-input-content {
            div>form>input {
                height: 33px;
            }

            div>form>input::placeholder {
                color: $lightgray;
                font-size: 13px;
            }
        }

        .common-input-name {
            bottom: 7px;
        }


    }
}