// TODO: This file should be pared down to only what is need for buy now and moved into that .scss file

@import "./common.scss";

$shadow: 0px 5px 18px 2px #999;

body {
    min-width: 1200px;
}

.landing-content {
    width: 1200px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
}

header.landing {
    display: flex;
    background: white;
    height: 90px;
    .landing-content {
        padding: 0;
    }
    .logo {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            height: 28px;
        }
        margin-right: -40px;
    }
    .content-links {
        display: flex;
        font-size: 15px;
        flex: 2 1 auto;
        margin-top: 6px;
        z-index: 10;
        span {
            cursor: pointer;
            flex: 1 1 auto;
        }
    }
    .buttons {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        height: 38px;
        font-size: 18px;
        margin-left: -80px;
        position: relative;
    }
    button {
        width: 135px;
        cursor: pointer;
    }
    button.logout {
        color: $blue;
        border: 2px solid $blue;
        background: $white;
        line-height: inherit !important; /* Overridess */
        font-size: inherit !important; /* Overridess */
        margin-right: inherit !important; /* Overridess */
    }
    button.signup {
        width: 135px;
        margin-left: -40px;
        background: $blue;
        color: $white;
        border: 1px solid $blue;
        border-radius: 6px;
        position: relative;
    }
    span.signup-today {
        font-size: 12px;
        color: black;
        position: absolute;
        width: 100%;
        left: 124px;
        top: 40px;
    }
}
.landing-content {
    .description {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 70px;
        .title {
            color: $krollblue;
            font-size: 28px;
            font-weight: bold;
            position: relative;
            .fa-home {
                cursor: pointer;
                position: absolute;
                left: -50px;
                font-weight: normal;
            }
        }
        button {
            right: 0;
            position: absolute;
            background: $krollblue;
            color: $white;
            font-size: 18px;
            font-weight: bold;
            border: 0;
            border-radius: 6px;
            box-shadow: 1px 6px 22px -4px #333;
            width: 200px;
            padding: 14px 0;
            .help {
                position: absolute;
                top: 70px;
                color: black;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }
}
section.banner {
    position: relative;
    height: 324px;
    z-index: 1;
    .landing-content {
        height: 100%;
        padding: 0 80px;
        position: relative;
    }
    .banner-video {
        display: block;
        width: 400px;
        height: 225px;
        position: absolute;
        left: 0;
        top: 50px;
    }
    &:after {
        background: no-repeat center/120% url('/assets/images/landing-background.png');
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-position: right;
    }
    .title {
        color: white;
        text-shadow: 3px 0px 21px #333;
        h1 {
            font-size: 65px;
        }
        h2 {
            font-size: 108px;
            line-height: 90px;
        }
    }
    .box {
        margin-left: 150px;
        display: flex;
        flex: 1 0 auto;
        background: rgba(0, 0, 0, 0.50);
        padding: 18px 30px;
        height: 245px;
        width: 435px;
        margin-top: 15px;
        .text {
            color: $white;
            font-size: 45px;
            line-height: 54px;
            width: 290px;
        }
    }
    .fa-map-marker {
        color: $blue;
        font-size: 150px;
        margin-top: 10px;
    }
    .dot {
        height: 20px;
        width: 20px;
        background: $blue;
        margin: auto;
        position: relative;
        top: 10px;
        border-radius: 20px;
    }
}
section.marketing {
    background: $krollblue;
    p {
        color: $white;
        text-align: center;
        font-size: 28px;
        max-width: 700px;
        margin: 10px auto 14px auto;
        font-weight: bold;
    }
}
section.highlights {
    background: white;
    .landing-content {
        padding: 40px 20px 60px 20px;
        flex-flow: wrap;
    }
    .highlights-info {
        flex: 0.6 1 auto;
        margin-top: 20px;
        width: 420px;
    }
    .laptop-image {
        flex: 1 1 0;
        position: relative;
        img {
            max-width: 600px;
            margin: 0 auto;
            display: block;
        }
        .circle {
            position: absolute;
            right: 43px;
            top: 0;
            background: $krollblue;
            color: $white;
            height: 150px;
            width: 150px;
            text-align: center;
            border-radius: 150px;
            font-size: 22px;
            line-height: 26px;
            padding-top: 54px;
            box-shadow: 1px 3px 6px 1px black;
            transform: rotate(7deg);
            b {
                padding-bottom: 4px;
                text-transform: uppercase;
            }
            .date {
                padding: 8px;
            }
        }
    }
    .highlights-leadin {
        font-size: 20px;
    }
    .highlights-title {
        color: $krollblue;
        font-size: 28px;
        font-weight: bold;
        position: relative;
        .fa-home {
            cursor: pointer;
            position: absolute;
            left: -50px;
            top: 5px;
            font-weight: normal;
        }
    }
    .highlights-body {
        font-size: 20px;
        margin-right: 90px;
        p {
            margin-top: 30px;
        }
        p:nth-child(2) {
            padding-right: 10px;
        }
        b {
            color: $krollblue;
            font-weight: bold;
        }
    }
}
section.subscriptions {
    background: $graywhite;
    .landing-content {
        display: block;
        padding: 30px 0 80px 0;
    }
    .right-banner {
        width: 67%;
        min-width: 800px;
        max-width: 1200px;
        font-size: 42px;
        font-weight: bold;
        color: $white;
        text-align: left;
        background: $krollblue;
        padding: 20px 85px;
        position: absolute;
        right: 0;
    }
    .free-trial {
        width: 200px;
        display: block;
        float: left;
        color: $error !important;
        text-decoration: underline;
    }
    .options {
        margin-left: 60px;
        margin-top: 140px;
        .lead-in {
            position: absolute;
            top: -150px;
            width: 100%;
            .help {
                font-size: 14px;
                margin-bottom: 8px;
            }
            .button {
                cursor: pointer;
                padding: 6px 0;
                width: 80%;
                height: 50px;
                margin: auto;
                color: $white;
                border-radius: 6px;
                box-shadow: 0px 6px 22px -1px #666;
                .tiny {
                    font-size: 10px;
                }
                div {
                    margin: 8px auto;
                }
            }
        }
        .info {
            width: 225px;
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            margin-top: 171px;
            position: relative;
            left: 4px;
            li.user-info-row {
                height: 70px;
                flex-direction: column;
            }
        }
        .option {
            box-shadow: $shadow;
            display: inline-block;
            vertical-align: top;
            margin-left: 8px;
            margin-right: 8px;
            text-align: center;
            width: 250px;
            position: relative;
            .title {
                color: $white;
                border-bottom: 4px solid $white;
                font-weight: bold;
                font-size: 20px;
                padding: 6px 0 4px;
            }
            .cost {
                color: $white;
                font-size: 22px;
                div {
                    position: relative;
                    top: 15px;
                    z-index: 1;
                    span {
                        font-weight: bold;
                        font-size: 30px;
                    }
                }
            }
            .bottom-triangle {
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                width: 0;
                height: 0;
                transform: scaleX(6.1) scaleY(4);
                transform-origin: top left;
                margin-bottom: 40px;
            }
            .subscribe {
                padding: 10px 0 15px 0;
                button {
                    color: $white;
                    border: 0;
                    border-radius: 3px;
                    font-weight: bold;
                    font-size: 14px;
                    box-shadow: 0px 2px 4px 1px #777;
                }
            }
            .subscribe.bottom {
                background: $white;
            }
            .user-info {
                color: $white;
                display: inline-block;
                border-radius: 6px;
                font-size: 12px;
                margin: 0 4px;
                height: 54px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .fa {
                font-size: 32px;
            }
            li.user-info-row {
                height: 70px;
                flex-direction: row;
            }
            .feature-sub-list {
                list-style-type: disc;
            }
            .feature-sub-list li {
                font-size: 10px;
                background: $whitegray !important;
                padding: 0;
                margin: 0;
                height: auto !important;
                display: list-item;
                text-align: left;
            }
        }
        .option.basic {
            border: 5px solid $blue;
            margin-top: 28px;
            z-index: 1;
            .lead-in .button {
                background: $blue;
            }
            .title {
                background: $fadedblue;
            }
            .cost {
                background: $blue;
            }
            .bottom-triangle {
                border-top: 10px solid $blue;
            }
            .subscribe {
                button {
                    background: $blue;
                    width: 220px;
                    
                }
            }
            .subscribe.bottom {
                border-top: 4px solid $blue;
                button {
                    width: 140px;
                }
            }
            .user-info {
                background: $blue;
                padding: 0 16px;
            }
            .coming-soon-feature {
                font-size: 10px;
            }
        }
        .option.pro {
            border: 5px solid $krollblue;
            .lead-in {
                top: -122px;
                .button {
                    background: $krollblue;
                }
            }
            .title {
                background: $fadedred;
                position: relative;
                line-height: 40px;
                font-size: 28px;
                padding-top: 24px;
            }
            .most-popular {
                position: absolute;
                top: -23px;
                left: 49px;
                background: $white;
                border: 5px solid $krollblue;
                color: $krollblue;
                font-size: 18px;
                border-radius: 43px;
                line-height: 30px;
                padding: 0 8px;
            }
            .cost {
                background: $krollblue;
            }
            .bottom-triangle {
                border-top: 10px solid $krollblue;
            }
            .subscribe {
                button {
                    background: $krollblue;
                    width: 220px;
                }
            }
            .subscribe.bottom {
                border-top: 4px solid $krollblue;
            }
            button.subscribe-pro {
                width: 110px;
                display: inline-block;
            }
            button.contact-us {
                width: 90px;
                margin-left: 10px;
                display: inline-block;
            }
            .user-info {
                background: $krollblue;
                padding: 0 8px;
            }
        }
        .option.enterprise {
            border: 5px solid $purple;
            margin-top: 28px;
            .lead-in {
                .button {
                    display: flex;
                    align-items: center;
                    background: $purple;
                }
            }
            .title {
                background: $fadedpurple;
                position: relative;
            }
            .cost {
                background: $purple;
            }
            .bottom-triangle {
                border-top: 10px solid $purple;
            }
            .subscribe {
                button.contact-us {
                    background: $purple;
                    width: 220px;
                }
            }
            button.subscribe-pro {
                width: 110px;
                display: inline-block;
            }
            .subscribe.bottom {
                border-top: 4px solid $purple;
                    
                button.contact-us {
                    width: 140px !important;
                    display: inline-block;
                }
            }
            .user-info {
                background: $purple;
                padding: 0 8px;
            }
        }
        .info, .option {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            li {
                height: 50px;
                background: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
        .option {
            li:nth-child(even) {
                background: $whitegray;
            }
        }
        .info {
            li {
                background: $graywhite;
            }
            li:nth-child(even) {
                background: $whitegray;
            }
        }
        .coming-soon {
            width: 300px;
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            .title {
                color: #465661;
                font-weight: bold;
                font-size: 24px;
                margin-top: 60px;
                margin-bottom: 10px;
            }
            .basic {
                border: 5px solid $blue;
                box-shadow: $shadow;
                background: $white;
                z-index: 1;
                position: relative;
            }
            .pro {
                border: 5px solid $krollblue;
                box-shadow: $shadow;
                background: $white;
                margin-bottom: 14px;
                position: relative;
                .star-triangle {
                    color: $white;
                }
            }
            ul {
                margin: 14px 0;
                text-align: left;
            }
        }
        .star-triangle {
            position: absolute;
            right: 0;
            top: 0;
            border-left: 60px solid transparent;
            border-top: 60px solid $krollblue;
            i.fa-star {
                position: absolute;
                right: 3px;
                top: -56px;
                font-size: 24px;
            }
        }
    }
    .footnotes {
        float: right;
        font-size: 10px;
        margin-top: 10px;
        margin-right: 140px;
        .left {
            float: left;
            width: 30px;
        }
    }
}
section.case-study {
    .landing-content {
        display: block;
        .description {
            margin-top: 60px;
            position: relative;
        }
        .case-study {
            width: 800px;
            color: $krollblue;
            font-size: 50px;
            font-weight: bold;
            text-align: center;
            margin: 0 auto 10px;
        }
    }
}
section.faq {
    margin-top: 140px;
    .faq-link {
        color: $krollblue !important;
    }
    .question {
        padding: 25px 40px;
        background: $graywhite;
        font-size: 30px;
        margin-bottom: 60px;
        position: relative;
        cursor: pointer;
        .answer {
            font-size: 18px;
            .header {
                color: $krollblue;
                font-weight: bold;
            }
            div {
                margin: 16px 0;
            }
        }
        .open, .close {
            float: right;
            user-select: none;
        }
        .close {
            position: relative;
            top: 8px;
            left: -1px;
        }
    }
}
section.books {
    margin-top: 80px;
    .landing-content {
        display: block;
        .right {
            float: right;
        }
        .purchase {
            font-weight: bold;
            .purchase-book {
                cursor: pointer;
                display: inline-block;
                margin-right: 80px;
            }
            img {
                width: 220px;
            }
            .divider {
                background: #CCC;
                width: 4px;
                height: 400px;
                float: right;
                position: relative;
                right: 70px;
                top: -40px;
            }
            .sbbi-book {
                margin-right: 0;
                float: right;
            }
            button {
                color: $white;
                background: $krollblue;
                border: 0;
                padding: 8px 34px;
                display: block;
                margin: 20px auto 0;
                font-size: 20px;
            }
        }
    }
}
// TODO: Move this to where its supposed to be
section.contact-us.standalone {
    background: inherit;
    max-width: 850px;
    margin-top: 0px;
    .landing-content {
        max-width: none;
        width: auto;
    }
    input {
        width: 100% !important; // TODO: Don't use important
    }
    .description {
        max-width: none;
    }
}
section.contact-us {
    background: white;
    margin-top: 140px;
    .landing-content {
        display: block;
        .description {
            margin-bottom: 10px;
        }
    }
    .fields {
        display: flex;
        flex-direction: row;
        .column {
            flex: 1 0 0;
            .note {
                font-size: 22px;
                width: 540px;
                margin-bottom: 30px;
            }
            .input {
                font-size: 20px;
                margin-bottom: 12px;
                input {
                    border-radius: 5px;
                    border: 1px solid $coolgray;
                    padding-left: 8px;
                    height: 45px;
                    line-height: 40px;
                    width: 90%;
                }
                textarea {
                    border-radius: 5px;
                    border: 1px solid $coolgray;
                    padding-left: 8px;
                    width: 100%;
                    height: 132px;
                }
            }
            .common-input-container {
                .common-input-line {
                    padding: 0;
                    display: block;
                }
                .common-input-name {
                    display: none;
                }
                .common-input-help {
                    display: none;
                }
                input {
                    border-radius: 5px;
                    border: 1px solid $coolgray;
                    padding-left: 22px;
                }
            }
            .send {
                display: flex;
                align-items: center;
                margin-top: 31px;
                a {
                    width: 100%;
                }
                button {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #14487f;
                    color: $white;
                    border: 0;
                    line-height: inherit;
                    font-size: 22px;
                    font-family: inherit;
                    margin-top: auto;
                    cursor: pointer;
                    // font-weight: bold;
                }
                button:hover {
                    background-color: $shadow;
                  }
            }
        }
    }
}
section.footer {
    background: white;
    text-align: center;
    color: $krollblue;
    padding-top: 120px;
    padding-bottom: 60px;
}
.wistia_embed {
    box-shadow: 1px 1px 10px 2px #CCC;
}
expiration-modal .modal-title {
    text-align: center;
    width: 100%;
    display: block;
}