.cp-body {
    display: flex;
    min-height: 100%;
    
    .background {
        background: no-repeat url('../images/landing-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    .banner {
        background: #EE3124;
        color: #F2F4F6;
        font-size: 28px;
        z-index: 10;
        position: absolute;
        top: 150px;
        width: 100%;
        min-width: 800px;
        height: 145px;
    }
    .above-banner {
        z-index: 20;
        margin-bottom: 6px;
        position: relative;
        margin-left: 60px;
        margin-top: 50px;
        color: #F2F4F6;
        font-size: 28px;
        line-height: 1.175;
    }
    .above-banner .title {
        font-size: 36px;
        margin-bottom: 6px;
    }
    .main {
        background: rgba(255,255,255,0.7);
        margin: 0;
        padding-bottom: 60px;
        margin-left: 20%;
        position: relative;
        flex: 1;
    }
    .logo img {
        width: 230px;
    }
    .logo {
        padding-left: 60px;
        padding-top: 100px;
    }
    .content {
        padding-left: 60px !important;
        position: relative;
        .box {
            background: #ECEDEF;
            padding: 26px 40px;
            margin-top: 40px;
            color: #465661;
            box-shadow: 0px 3px 3px #888888;
            width: 700px;
        }
    }
    .save-profile {
        width: 60%;
        margin: 30px auto 0;
        padding: 8px 0;
    }
    .form-error {
        color: $error;
        visibility: hidden;
    }
}
.cp-body .content .box {
    form {
        margin-right: -15px;
    }
    .flex-row {
        display: flex;
        .row-item {
            flex: 1;
            margin-bottom: 15px;
            div {
                text-align: left;
                margin-bottom: 3px;
            }
            input {
                width: 100%;
                padding: 6px 10px;
            }
        }
        .input-item {
            margin-right: 15px;
        }
    }
}