@import "../colors.scss";

crp-summary .step {
  width: 90%;
  padding-top: 24px;
}
crp-summary .study-group {
  padding: 10px;
}
crp-summary .study-group.active {
  color: $skyblue
}