.view-companies-link {
    display: flex;
}

.common-input-error.benchmarking-error {
    text-align: left;

    .benchmarking-margin-top {
        margin-top: 16px;
    }
}