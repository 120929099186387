@import "./colors.scss";

.carousel-indicators li {
    height: 10px;
    width: 10px;
    background-color: $darkgray;
    border-radius: 50%;
}

.carousel-indicators {
    margin-bottom:0;
    height: 1rem!important;
}

.carousel-inner {
    max-height: 300px;
}

.dashboard-card {
    padding-top: 1rem;
    padding-bottom: .5rem;
    background-color: $purewhite;
}

.modules-box{
    width: 180px;
    height:100px;
    font-size:13px;
    border-style: groove;
    border-top: 5px solid #003c69;
}

.modules-box-available{
    width: 180px;
    height:100px;
    font-size:13px;
    border-style: groove;
    border-top: 5px solid #1EB9EB;
}

.modules-box:hover {
    cursor: pointer;
    text-decoration: underline;
  }

.title-margin{
    margin-right: 40px;
}
 
.dashboard-card div {
    text-align: left;
    margin-right: 2rem;
    margin-bottom: 1rem;
}


.dashboard-card hr {
    width: 90%;
    border-top:solid 1px;
}

.dashboard-card {
    #subtitle {
        margin-bottom: 2px;
    }
    #maintitle {
        font-weight: bolder;
        
    }
    .card-content {
       a {
           color: $blue;
           font-weight: bold;
       }
       a:hover {
           cursor: pointer;
       }
       li {
           margin-bottom: 1rem;
       }
       font-size: 11pt;
    }

    .padded {
        padding-left: 2rem;
    }

    .scroll-section {
        overflow-y: scroll;
        max-height: 200px;
    }

    min-height: 325px;
    max-height: 325px;
}

.tall-card {
    min-height: 600px;
    max-height: 600px;
}

.launch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: auto;
    padding-right: auto;
    max-width: 110px;
    background-color: $blue;
    min-height: 110px;
    max-height: 110px;
    text-align: center;
    border-radius: 50%;
    color: $white;
    box-shadow: 
    8px 8px 20px rgba(0, 0, 0, 0.2),
    5px 5px 15px rgba(0, 0, 0, 0.19);
    h5 {
        text-align: center;
        font-size: 12pt;
        font-weight: bolder;
        text-align: center;
        padding-top: auto;
        padding-bottom: auto;
        margin-bottom:0;
    }
}

.disabled-round {
    background-color: $coolgray !important;
}
.disabled-round:hover {
    box-shadow: 
    8px 8px 20px rgba(0, 0, 0, 0.2),
    5px 5px 15px rgba(0, 0, 0, 0.19) !important;
    cursor: not-allowed !important;
    background-color: $coolgray !important;
}

.launch-button:hover {
    box-shadow: 
    10px 10px 25px rgba(0, 0, 0, 0.2),
    8px 8px 20px rgba(0, 0, 0, 0.19);
    cursor: pointer;
    background-color: $darkblue;
}

.launch-title {
    border-radius: 25px;
    background-color: $graywhite;
    margin-left: 1rem;
    h5 {
        margin-bottom: 0;
        margin-top: 1rem;
    }
    h4 {
        font-weight: bolder;
        margin-bottom: 1rem;
    }
    max-width: 72%;
    min-width: 72%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 5.5rem;
    padding-left: 1rem;
    margin-top: 1rem;
}
.module-header {
    min-height: 3rem;
    h4 {
        float:left;
    }
    h5 {
        margin-bottom: .2rem;
    }
    #homeLink {
        float:right;
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 0;
    }
    #homeLink:hover {
        text-decoration: underline;
    }
}
.module-info {
    background-color: $graywhite;
}

.module-button {
    border-radius: 15px;
    width: 100%;
    padding-top:1rem !important;
    padding-bottom:1rem !important;
    box-shadow: 
    8px 8px 20px rgba(0, 0, 0, 0.2),
    5px 5px 15px rgba(0, 0, 0, 0.19)!important;
}
.product-news-item {
    margin-top: 1rem;
}

.red-text {
    color: $krollblue;
}

.blue-text {
    color: $blue;
}

.non-bold {
    font-weight: normal !important;
}

.dashboard-module {
    margin-bottom: 60px;
}
.dashboard-section-header {
    font-weight: bold;
    color: $darkgray;
    font-size: 18px;
}
.dashboard-intro {
    margin-bottom: 30px;
    color: $darkgray;
    .large {
        font-size: 22px;
    }
    div {
        margin-bottom: 16px;
    }
    div:last-child {
        margin-bottom: 0;
    }
    a {
        color: $darkerblue !important;
        font-weight: bold;
    }
    .title {
        color: $krollblue;
        font-size: 22px;
        font-weight: bold;
    }
}

.coc-dashboard-estimates {
    width: 100%;
}
table.coc-dashboard-estimates td:first-child {
    width: 40px;
}
table.coc-dashboard-estimates td {
    background: $white;
    padding: 10px 20px;
    border: 4px solid $purewhite;
    border-collapse: separate;
}
.coc-dashboard-estimates-body tr:hover {
    cursor: pointer;
}
.coc-dashboard-estimates-body tr:hover td {
    background: $blue;
    color: $white;
    transition: background-color 0.2s ease;
}
button[type="submit"].coc-goto-estimates-button {
    margin-top: 10px;
    margin-right: 0px;
    margin-left: auto;
    
}
.dashboard-lists {
    margin-bottom: 20px;
}
.estimates-list {
    padding-bottom: 20px;
}
.dashboard-list-title {
    font-weight: bold;
    margin-bottom: 20px;
}
.dashboard-button {
    float: left;
    width: 25%;
}
.dashboard-button button {
    width: 100%;
}
.review-estimates {
    width: 100%;
    text-align: center !important;
    margin-right: 0;
    cursor: pointer;
    margin-top: 1rem;
    text-decoration: underline;
}
.review-estimates.disabled {
    color: $coolgray;
    cursor: not-allowed;
}
.start-estimate-text {
    margin-left: 27%;
    margin-right: 15%;
}
.description-title {
    color: $krollgreen;
}
.module-availability {
    font-style: italic;
}
.subscription-title {
    font-size: 9pt;
}
.subscription-status {
    color: $coolgray;
}
.subscribe-now {
    color: $blue;
    text-align: center;
}
.subscribe-now a {
    cursor: pointer;
}
.submit-title {
    font-size: 16px;
}
.module-title {
    font-size: 14px;
}
.subscription-status-message {
    color: $coolgray;
    text-align: center;
    margin-top: 5px;
}
#st-box{
    a:hover{
    text-decoration: underline;
    cursor:pointer;
    }
}

.container1 { 
    width:700px; 
    height:350px; 
    background-color:white; 
    padding-top:20px; 
    padding-left:10px; 
    padding-right:10px; 
    padding-bottom: 25px; 
} 
#st-box { 
    float:left; 
    text-align:left; 
    width:150px; 
    height:265px; 
    background-color:#FFFFFF; 
    border:solid black; 
} 
#nd-box { 
    float:left; 
    margin-left:20px; 
    text-align:left; 
    width:150px; 
    height:265px; 
    background-color:#FFFFFF; 
    border:solid black; 
} 
#rd-box { 
    float:left; 
    margin-left:20px; 
    text-align:left; 
    width:150px; 
    height:265px; 
    background-color:#FFFFFF; 
    border:solid black; 
} 
#th-box { 
    float:left; 
    margin-left:20px; 
    text-align:left; 
    width:150px; 
    height:265px; 
    background-color:#FFFFFF; 
    border:solid black; 
} 