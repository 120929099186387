@import "./colors.scss";

.study-container {
    .toggle-regression-container {
        float: right;
        margin-right: 100px;
        position: relative;
        top: -6px;
        input {
            display: none;
        }
    }

    .scenario-options {
        margin-bottom: 6px;
    }
    .study-equation-type {
        color: $krollblue;
        font-size: 1rem;
        font-weight: bold;
    }
}

.scenario-study-container {
    .toggle-regression-container {
        float: right;
        margin-top: 20px;
        margin-top: -11px;
        label {
            margin-bottom: 0;
        }

        input {
            display: none;
        }
    }
}

.toggle-regression {
    display: inline-block;
    top: 5px;
    left: 12px;
    width: 42px;
    height: 22px;
    background: $coolgray;
    position: relative;
    border-radius: 50px;
    box-shadow: inset 0px 1px 1px rgba(236, 255, 215, 0.5), 0px 1px 0px rgba(240, 253, 205, 0.2);
    &:before {
        content: "";
        color: $white;
        position: absolute;
        left: 6px;
        top: 0;
        z-index: 0;
        font-size: 14px;
    }

    label {
        display: block;
        width: 18px;
        height: 18px;
        cursor: pointer;
        position: absolute;
        top: 2px;
        left: 3px;
        z-index: 1;
        background: $white;
        border-radius: 50px;
        transition: all 0.4s ease;
        box-shadow: 0px 2px 5px 0px rgba(240, 253, 205, 0.2);
    }

    input[type="checkbox"] {
        visibility: hidden;
        &:checked + label {
            left: 22px;
        }
    }
}
.toggle-regression.active {
    background-color: $krollgreen;
}
