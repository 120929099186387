@import "../../colors.scss";

wacc,
coe-summary-capm,
coe-summary-fisher-effect,
coe-summary-conclusion,
cod-summary-capital,
cod-summary-fisher-effect,
cod-summary-conclusion,
wacc-summary-capital,
wacc-summary-conclusion {
  .wacc-container {
    margin-top: 80px;
  }
  .toggle-key {
    position: relative;
    button {
      color: $krollgreen;
      background: white;
      padding: 2px 16px;
      font-size: 14px;
      border: 0;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -40px;
    }
  }
  .equation.key {
    opacity: 0.5;
    margin-bottom: 30px;
  }
  .equation {
    text-align: center;
    .element:first-of-type {
      color: $krollblue;
      font-weight: bold;
      .name {
        width: 100px;
      }
    }
    .element {
      display: inline-block;
      margin: 0 8px;
      width: 90px;
      vertical-align: top;
      position: relative;
      .display {
        font-size: 28px;
        font-weight: bold;
      }
      .name {
        font-size: 14px;
        font-weight: bold;
      }
      help-icon {
        position: absolute;
        right: -10px;
        top: -5px;
        color: $krollshadow;
      }
    }
    .element.slim {
      width: 20px;
    }
    .element .crp-elem {
      color: $punch;
      font-weight: bold;
      &-name {
        color: $punch;
      }
    }
  }
  .mask {
    font-weight: bolder;
  }
  .options {
    margin-top: 80px;
  }
  .bottom {
    padding-bottom: 80px;
  }
  .save {
    margin: 50px auto 0 auto;
  }
  .investee-country {
    display: inline-flex;
    align-items: center;
    margin: 20px 0px;
  }
}
