@import "colors.scss";
body .ui-dialog .ui-dialog-titlebar {
  background-color: $white;
  text-align: left;

  .ui-dialog-title {
    font-family: "nunito-sans";
    font-weight: 700;
    color: $krollblue;
  }
}
// input
input:focus-visible {
  outline-color: $coolgray;
}
/*checkbox*/
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
  padding: 0; // 2. Remove the padding in IE 10-
  border-radius: 5px;
  border: 2px solid $coolgray;
  width: 20px;
  height: 20px;
}
input[type="checkbox"]::before {
  content: "\2713";
  color: white;
  position: relative;
  left: 3px;
  bottom: 3px;
  font-size: 16px;
}
input[type="checkbox"]:checked {
  appearance: none;
  -webkit-appearance: none;
  background-color: $krollblue;
  border-radius: 5px;
  border: 2px solid $coolgray;
}
/*dorp down*/
.dropdown-item.active,
.dropdown-item:active {
  background-color: $skyblue;
}
.form-control:focus {
  border-color: $coolgray;
  box-shadow: 0 0 0 0.1rem rgba(168, 168, 169, 25);
}
button {
  border-radius: 5px;
}
// ngb Calander
.ngb-dp-weekdays .ngb-dp-weekday.small {
  color: $krollgreen;
}
.ngb-dp-arrow .btn-link,
.ngb-dp-week .btn-light,
.ngb-dp-week .text-muted {
  color: $krollshadow;
  margin: 0;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: $thinlightgray;
  color: $krollshadow;
}
.dropdown-item {
  color: $krollshadow;
}
.trends-over-time-modal .ui-dialog .ui-dialog-titlebar {
  position: fixed;
  width: inherit;
  z-index: 1;
}
.trends-over-time-modal .ui-dialog .ui-dialog-content {
  top: 56px;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
  }
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
