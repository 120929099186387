@import "./colors.scss";

.tabs {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: #ffffff;

    .tab {
        border: solid #dee2e6;
        border-width: 0 0 1px 0;

        .tab-header {
            background: rgb(255, 255, 255);
            padding: 1rem;
            transition: box-shadow 1s ease 0s;
            margin: 2px 20px 0px 0px;
            cursor: pointer;

            &.tab-active {
                font-weight: 600;
                border-bottom: 4px solid $krollgreen;
                color: $krollgreen;
            }

            &.last-child {
                margin-right: 0;
            }
        }
    }
}

.accordion {
    table {
        width: 100%;

        td,
        th {
            text-align: left;
            padding: 0.4em;
            font-weight: inherit;
        }

        thead tr:first-child th {
            text-transform: uppercase;
            font-weight: bold;
        }

        tr:nth-child(even) {
            background: $thinlightgray;
        }

        tr:nth-child(odd) {
            background: #fff;
        }

        tr {

            th:nth-child(n + 1),
            td:nth-child(n + 1) {
                text-align: center;
            }

            th:first-child,
            td:first-child {
                text-align: left;
            }

            th {
                .common-help-icon {
                    i {
                        top: 4px;
                        left: 5px;
                    }

                    .coc-help-pulse-inner,
                    .coc-help-pulse-outer {
                        left: 8px;
                    }
                }
            }
        }
    }

    i {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1em;
        color: $krollgreen;
        cursor: pointer;
    }
}

.accessLink {

    a,
    a:not([href]):not([class]) {
        cursor: pointer;
        color: $krollgreen;

        &:hover {
            text-decoration: underline;
            color: $krollgreen;
        }

        &:active {
            color: $krollgreen;
        }
    }

    span {
        cursor: pointer;
    }
}

.isSubCatPadding {
    padding-left: 50px;

    .category.has-children:after {
        position: relative;
    }
}

.isSuperSubCatPadding {
    padding-left: 100px;
}

.module {
    font-family: "nunito-sans";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.2px;
    cursor: pointer;
    margin: 20px 5px 0;
    width: 220px;
    display: inline-block;
}

.module:hover,
.module.active {
    color: $duff-light-blue;
    font-weight: bold;
    text-decoration-color: $duff-light-blue;
    font-weight: 600;
}

.estimate-modules {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: #ffffff;

    .estimate-module-0 {
        border-bottom: 1px solid #dee2e6;

        .estimate-module-0-header {
            background: rgb(255, 255, 255);
            padding: 1rem;
            transition: box-shadow 1s ease 0s;
            margin: 2px 20px 0px 0px;
            cursor: pointer;

            &.estimate-module-active {
                font-weight: 600;
                border-bottom: 4px solid $krollgreen;
                color: $krollgreen;
            }
        }
    }

    .estimate-module-1 {
        border-bottom: 1px solid #dee2e6;

        .estimate-module-1-header {
            background: rgb(255, 255, 255);
            padding: 1rem;
            transition: box-shadow 1s ease 0s;
            margin-top: 2px;
            cursor: pointer;

            &.estimate-module-active {
                font-weight: 600;
                border-bottom: 4px solid $krollgreen;
                color: $krollgreen;
            }
        }
    }

    .estimate-module-2 {
        border-bottom: 1px solid #dee2e6;

        .estimate-module-2-header {
            background: rgb(255, 255, 255);
            padding: 1rem;
            transition: box-shadow 1s ease 0s;
            margin-top: 2px;
            cursor: pointer;

            &.estimate-module-active {
                font-weight: 600;
                border-bottom: 4px solid $krollgreen;
                color: $krollgreen;
            }
        }
    }
}

.resources {
    display: flex;

    .left-content {
        flex: 1 0 0;
        margin: 0 120px 0 40px;

        .help {
            color: rgba(100, 100, 100, 0.6);
            text-align: center;
            margin: 0px 0 20px -100px;
        }
    }

    .right-content {
        flex: 1 0 0;
        margin-right: 40px;

        .note-box {
            margin-top: 25px;
            border-bottom: 2px solid $krollblue;
            border-top: 2px solid $krollblue;
            padding-top: 20px;
            padding-bottom: 10px;
        }

        .header {
            color: $krollblue;
        }
    }

    .section {
        margin-bottom: 30px;
    }

    .header {
        color: $krollblue;
        font-weight: bold;
        font-size: 20px;
    }

    .category {
        font-size: 16px;
        font-family: "nunito-sans";
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    .category.has-children:after {
        content: "\f04b";
        font-family: FontAwesome;
        color: $coolgray;
        position: absolute;
        left: -24px;
        // top: 0px;
        transform: rotate(90deg);
    }

    .category.collapsed:after {
        transform: rotate(0deg);
    }

    .book {
        font-size: 16px;
        margin-left: 20px;
    }
}