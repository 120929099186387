@import "../colors.scss";

international-inputs .step {
    width: 90%;
    padding-top: 24px;
}
international-inputs .industry-not-available {
    margin: 5px 0px;
    display: inline-flex;
}

