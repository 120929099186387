.main section.subscriptions { // Used on the Dashboard
    background: inherit;
    .landing-content {
        padding: 0;
        margin: 0;
        width: 1050px;
    }
    .options {
        margin-left: 0px;
    }
    .footnotes {
        margin-right: 40px;
        float: none;
        text-align: center;
    }
}