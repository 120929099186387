@import '../colors.scss';
.summary-panel {
  margin-top: 30px;
  margin-left: 30px;
}
.summary-separator {
  width: 60%;
  background: $blue;
  display: inline-flex;
}
.summary-header-1 {
  // border-bottom: 1px solid $krollblue;
  font-size: 1.25rem;
  font-weight: bold;
  color: $krollblue;
}
.summary-header-2 {
  // border-bottom: 1px solid $krollblue;
  font-size: 1rem;
  font-weight: bold;
  color: $krollblue;
}
