@import "../colors.scss";
wacc-summary .step {
  width: 90%;
  padding-top: 24px;
}
wacc-summary .study-group {
  padding: 10px;
}
wacc-summary .study-group.active {
  color: $skyblue
}