.common-input-content>div {
    width: 100%;
}

.common-combobox {
    position: relative;

    & .common-input {
        margin-bottom: 0;
    }
}

.common-combobox-list {
    border-radius: 5px;
    border: 1px solid $coolgray;
    border-top: 0;
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.common-combobox-list-item {
    text-align: left;
    padding: 10px 20px;
    color: $krollshadow;
    cursor: pointer;
}

.tax-rate {
    .common-combobox-list-item {
        padding: 10px 7px;
    }
}

.common-combobox-list-item.disabled {
    cursor: not-allowed;
    background: #f2f4f6;
}

.inline-disable-text .common-combobox-list-item.disabled {
    &>div {
        display: inline;
    }
}

.common-combobox-list-item.disabled .disabled-text {
    // font-size: 1rem;
    margin-left: 5px;
}

.common-combobox-list-item:hover {
    border-radius: 5px;
    background-color: $thinlightgray;
    color: $krollshadow;
}

.common-combobox-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfM19Lb3BpZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4Ig0KCSB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1OSAzMS45IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1OSAzMS45OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoxLjU7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjU3LjYsMS44IDI5LjUsMjkuOCAyOS41LDI5LjggMS41LDEuOCAiLz4NCjwvc3ZnPg0K");
    cursor: pointer;
}

.location,
.marketIndex,
.currency {
    .common-combobox-list {
        max-height: 187px !important;
        overflow-y: scroll !important;
        z-index: 15 !important;
    }
}

.investor {
    .common-combobox-list {
        max-height: 187px !important;
        overflow-y: scroll !important;
        z-index: 15 !important;
    }
}

.lookBack {
    .common-combobox-list {
        z-index: 15 !important;
    }
}

.marketIndex {
    .common-combobox-list {
        max-height: 170px !important;

    }
}

.location .common-combobox-list-item:nth-child(1) {
    border-bottom: 1px solid black;
    // background-color: $thinlightgray;
}

.currency .common-combobox-list-item:nth-child(3) {
    border-bottom: 1px solid black;
}

.investorPinned .common-combobox-list-item:nth-child(2) {
    border-bottom: 1px solid black;
    // background-color: $thinlightgray;
}

.commoncss .common-input-name-stack {
    bottom: 0px !important;
    position: relative !important;
    font-weight: normal !important;

}


.commoncss .common-input-line {
    padding: 0px !important;
}


.tbl-taxreate .common-input-line {
    padding-right: 0px;
}


.relevered-betas-section .common-input-line {
    font-size: 15px;
}


.custom-datepicker {

    .common-input-line {
        padding-left: 0px !important;

        .common-input-name {
            bottom: -3px;
            font-weight: 600;
        }

        .common-help-icon {
            margin-top: 0px !important;
        }
    }


}

// .currency .common-combobox-list-item:nth-child(1) {
//     // background-color: $thinlightgray;
// }

.investor .commoncss .common-combobox-list-item:nth-child(3) {
    border-bottom: 1px solid black;
}