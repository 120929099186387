@import "../colors.scss";

.has-children:after {
    content: "\f04b";
    font-family: FontAwesome;
    color: $coolgray;
    transform: rotate(90deg);
    font-size: 1rem;
    position: absolute;
}

.has-children-disabled:after {
    content: "\f068";
    font-family: FontAwesome;
    color: $coolgray;
    transform: rotate(90deg);
    font-size: 1rem;
    position: absolute;
}

.collapsed:after {
    transform: rotate(0deg);
}

.beta {
    &-header {
        .label {
            padding-left: 2rem;
            font-size: 1.2rem;
            font-weight: bold;
        }
    }

    font-size: 0.8em;

    .h-40 {
        height: 40px;
    }

    .sticky-label {
        position: absolute;
        background: white;
        height: 22px;
        z-index: 9;
        left: 0px;
    }

    .currency {
        display: block;
    }

    .statistics-label,
    .unleveringDTC {
        padding-left: 3rem;
        color: $krollgreen;
        font-size: 0.8rem;
    }

    .unleveringDTC {
        position: relative !important;
        top: -8px !important;
    }

    .statistics-header {
        font-weight: bold;
        margin-left: 36px;
    }

    .tax-rate {
        &-height {
            height: 3.125rem;
        }

        position: relative;
        right: 16px;
        bottom: 16px;
    }

}

.significant {
    text-align: center;
    margin-left: -36px;
}