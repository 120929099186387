.sensitivity-data {
    text-align: center;
    .panel:first-child {
        margin-right: 20px;
    }
    .panel:last-child {
        margin-left: 20px;
    }
    .panel {
        flex: 1 0 0;
        .panel-title {
            font-size: 18px;
            text-align: left;
        }
    }
    table {
        border: 2px solid #2F528F;
        thead {
            border: 2px solid #2F528F;
        }
        th {
            width: 14.285%;
            border: 1px solid #2F528F;
        }
        td {
            border: 1px solid #2F528F;
        }
    }
}