@import "../colors.scss";

coe-summary .step {
  width: 90%;
  padding-top: 24px;
}
.estimate-controls.estimate-edit-controls {
  text-align: right;
  padding: 40px;
}
.estimate-controls .estimate-edit-button {
  display: inline-block;
}
coe-summary .study-group {
  padding: 10px;
}
coe-summary .study-group.active {
  color: $skyblue

}
