
@import "../colors.scss";
cod-summary .step {
  width: 90%;
  padding-top: 24px;
}
cod-summary .study-group {
  padding: 10px;
}
cod-summary .study-group.active {
  color: $skyblue
}
