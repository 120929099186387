.add-industry {
    background-color: #f9f9f9;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
}
.add-industry button {
    border: none;
    background-color: transparent;
    color: #b7b7b7;
}

.add-scenario-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    & .common-input-name {
        justify-content: flex-start;
    }

    & .add-industry {
        background-color: #e8e8e8;
    }
}

.remove-industry-link {
    color: $krollblue;
    cursor: pointer;
}

.view-industries-link {
    display: flex;
    justify-content: space-around;
}
