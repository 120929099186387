@import "./colors.scss";

.comparative-risk {
    color: $coolgray;
    font-size: 14px;
    margin-bottom: 40px;
    .name {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .data {
        display: flex;
        flex-direction: row;
        .industry, .subject {
            flex: 1 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .label {
                text-align: center;
                flex: 3 0 0;
                max-width: 300px;
            }
            .value {
                text-align: left;
                flex: 1 0 0;
                font-size: 16px;
                margin-left: 30px;
            }
        }
        .is-risky {
            flex: 1 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .fa {
                color: $krollgreen;
                font-size: 60px;
            }
            .orange {
                color: $krollgreen;
                font-weight: bold;
            }
            .label {
                flex: 4 0 0;
                text-align: center;
                margin-right: 14px;
            }
            .arrow {
                flex: 1 0 0;
            }
        }
    }
}