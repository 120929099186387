@import "./results/summary.scss";
@import "./results/wacc.scss";

results {
    .tabs {
        text-align: center;
        padding: 16px 10px 0 0;
        
        h2 {
            display: inline-block;
            margin-right: 20px;
            cursor: pointer;
            outline: none;
            color: $lightgray;
            padding:0 16px;
        }
        h2.active {
            color: $krollblue;
            font-weight: bold;
        }
    }
}
