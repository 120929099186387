.common-combobox form,
.common-text-input form {
  position: relative;
}
.common-input {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  padding: 10px 14px;
  line-height: 1.25;
  color: #465661;
  border-radius: 5px;
  border: 1px solid $coolgray;
  margin-bottom: 10px;
}
.tax-rate{
    .common-input{
        line-height: 1.75;
        margin-left: 1px;
    }
}
.common-input::placeholder {
  color: $lightgray;
}
.common-input.ng-invalid {
  border: 1px solid $error;
  outline: none;
}
.common-datepicker {
  position: relative;
}
.common-datepicker input {
  cursor: pointer;
}
.common-datepicker i.fa-calendar {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 33px;
  color: $coolgray;
  cursor: pointer;
}
.common-checkbox input {
  outline: none;
  width: 20px;
  display: inline-block;
}
.common-checkbox label {
  display: inline-block;
}
