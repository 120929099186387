/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/css/font-awesome.css";
@import "theme";
@import "~bootstrap/scss/bootstrap.scss";
@import "./create-profile.scss";
@import "./comparative-risk.scss";
@import "./dashboard.scss";
@import "./study.scss";
@import "./resources-library.scss";
@import "./estimate/results.scss";
@import "./estimate/sensitivity.scss";
@import "./buy-now.scss";
@import "./landing-and-buy-now.scss";
@import "./tear-sheet.scss";
@import "./benchmarking/benchmarking.scss";
@import "./category-width.scss";
@import "./common-typeahead.scss";
@import "./estimates.scss";
@import "./international-estimate/coe-inputs.scss";
@import "./colors.scss";
@import "./risk-table.component.scss";
@import "./international-estimate/coe-summary.scss";
@import "./common-table.scss";
@import "./common-combobox.scss";
@import "./common-controls.scss";
@import "./international-estimate/kd-wacc.scss";
@import "./international-estimate/crp-summary.scss";
@import "./international-estimate/cod-summary.scss";
@import "./equations.scss";
@import "./international-estimate/wacc-summary.scss";
@import "./common-input-container.scss";
@import "./international-estimate/common.scss";
@import "./international-estimate/information-sidebar.scss";
@import "./international-estimate/summary-log.scss";
@import "./international-estimate/international-inputs.scss";
@import "./international-estimate/fisher-effect.scss";
@import "./content-card.component.scss";
@import "override";
@import "./beta/beta-comparblecompanies.scss";
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

/* General Overrides */

html,
body {
    color: $krollshadow !important;
    font-family: "nunito-sans" !important;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.fourteenpx {
    font-size: 14px;
}

.footer-text-muted {
    a {
        color: $krollgreen;
    }

    font-size: 12px;
    color: $coolgray;
    margin: 20px 0px 5px;
}

.loading {
    font-style: italic;
    text-align: center;
}

button:focus {
    outline: none;
}

button[type="submit"],
a.button-like {
    cursor: pointer;
    background: $krollblue;
    color: white;
    border: 0;
    display: block;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0px 0px 1px 1px #ededed;
    transition: background-color 0.3s ease;
    padding: 8px 16px;
    min-width: 144px;
    text-decoration: none;
    border-radius: 5px;
}

button[type="submit"][disabled] {
    background: $coolgray;
    cursor: not-allowed;
    color: $white;
}

button[type="submit"]:not([disabled]):hover {
    background-color: $steelblue;
    color: $white;
}

button[type="reset"] {
    border: 2px solid $coolgray;
    display: block;
    background: none;
    padding: 8px 16px;
    // min-width: 180px;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

button[type="reset"]:hover {
    text-decoration: none;
}

.line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-top: 39px;
}

img {
    outline: none;
    border-style: none;
}

.hidden {
    display: none;
}

body a {
    color: inherit;
    /* Override Bootstrap */
}

// .container {
//     margin: initial !important; /* Override Bootstrap */
// }

input::-ms-clear {
    display: none;
}

.font-size-12 {
    font-size: 12px;
}

/* Login */
.login-background {
    min-height: 380px;
    width: 100%;
    background: #ee3124;
    text-align: center;
    padding-top: 80px;
}

.login-background img {
    width: 320px;
}

.login-box {
    position: relative;
    top: -200px;
    background: #e6eaed;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
    padding: 30px;
    width: 550px;
    margin: auto;
}

.login-header {
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
}

button[type="submit"].login-button {
    padding: 24px 170px;
    margin: 40px auto 0;
}

.loading-spinner {
    width: 60px;
    margin: auto;
    display: block;
}

/* Navigation */
nav.home-nav {
    background: #f6f6f6;
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 13;
}

.home-nav .logo {
    background: white;
    cursor: pointer;
}

.home-nav .logo img {
    width: 100%;
    // padding: 50px 20px 30px;
    padding: 55px 40px 33px;
    // image-rendering: -webkit-optimize-contrast;
    margin-top: -20px;
}

.banner {
    background: #edeef0;
    height: 58px;
    width: 80%;
    padding: 15px;
    margin-top: 62px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    margin-left: 300px;
}

.banner .text {
    margin: 20px;
}

.survey {
    float: right;
}

.Cancel {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 18px;
}

.nav-main {
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
    overflow: auto;
}

.nav-list {
    list-style: none;
    padding: 0;
    margin-top: 30px;
}

.close-Banner {
    width: 80%;
    /*border-radius: 25px;*/
    border: 2px solid Black;
    padding: 15px 15px 15px 15px;
    margin: 20px 20px 20px 20px;
    background: #a4d3ee;
    overflow: visible;
    box-shadow: 5px 5px 2px #888888;
    position: relative;
}

.cls {
    position: absolute;
    color: black;
    top: 98px;
    right: -1605px;
}

.nav-list li {
    padding: 10px 10px 10px 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.nav-list li:hover {
    text-decoration: none;
    background: #dadddf;
}

.nav-list .icon {
    font-size: 20px;
}

.nav-list li>span {
    padding: 2px 0 0 10px;
    font-size: 16px;
    //flex-grow: 1;
}

.nav-list .active {
    background: #dadddf;
}

.nav-list .active:focus {
    outline: 0;
}

.nav-footer {
    font-size: 12px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .first {
        flex-grow: 1;
    }
}

.nav-footer-section {
    padding: 2px 0;

    div.nav-footer-link {
        cursor: pointer;
    }

    div.nav-footer-link:hover {
        text-decoration: underline;
    }

    a:hover {
        text-decoration: underline;
        color: $krollblue;
        cursor: pointer;
    }
}

.icon-badge {
    background-color: $magenta;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: -7px;
    margin-top: 3px;
}

/* Main Layout */
.main {
    margin-left: 300px;
    background: $white;
    min-height: 100%;
    min-width: 1200px;
}

header {
    background: $white;
    height: 80px;
    padding: 0 30px;
}

.page-title {
    font-size: 13px;
    line-height: 60px;
}

.module-heading {
    font-size: 1.5rem;
    line-height: 76px;
    margin-top: 5px;
    color: $krollblue;
    font-weight: bold;
}

.module-note {
    font-size: 19px;
    line-height: 76px;
    font-family: "nunito-sans";
    color: rgb(167, 168, 169);
    font-weight: normal;
}

.page-title .heading {
    font-size: 20px;
}

.page-title span {
    font-size: 16px;
}

.subs {
    position: relative;
    left: 600px;
    font-size: 16px;
    font-weight: bold;
    color: #6b1f7c;
}

.logout {
    float: right;
    margin-right: 20px;
    line-height: 65px;
    font-size: 13px;
    cursor: pointer;
    font-family: "nunito-sans";
}



.logout:hover .logout-text {
    text-decoration: underline;
}

.logout svg {
    margin-right: 5px;
    position: relative;
    top: -3px;
}

.main .content {
    padding: 30px 15px;
    max-width: 2560px;
}

.purchase-now {
    position: relative;
    float: right;
    margin-top: 20px;
    margin-right: 60px;

    a,
    button {
        border-radius: 6px;
        padding: 4px 24px;
        font-weight: normal;
        font-size: 18px;
        min-width: 140px;
    }

    div {
        position: absolute;
        font-size: 11px;
        text-align: center;
        margin-top: 4px;
        width: 100%;
    }
}

.renew-now {
    position: relative;
    float: right;
    margin-top: 25px;
    margin-right: 30px;
    background-color: #43b04b;
    border-radius: 4px;
    padding: 4px 10px;
    font-weight: normal;
    font-size: 13px;
    color: white;
    border: none;

    button[type="submit"],
    a.button-similar {
        cursor: pointer;
        display: block;
        color: white;
        transition: background-color 0.3s ease;
        text-decoration: none;
    }
}

/* Estimates List */
// .coc-estimates-section {
//     max-width: 1200px;
// }

// table.coc-estimates {
//     width: 100%;
//     margin-top: 40px;
// }

// table.coc-estimates th {
//     color: #465661;
//     padding: 2px 20px;
//     font-weight: bold;
// }

// table.coc-estimates th:first-child {
//     width: 40px;
// }

// table.coc-estimates td {
//     background: $thinlightgray;
//     padding: 10px 20px;
//     border: 4px solid #fff;
//     border-collapse: separate;
// }
coc-estimates-table {
    .resources .left-content .help {
        margin-top: 10px;
    }
}

.coc-estimates-body tr:hover {
    .loading {
        text-align: center;
    }

    cursor: pointer;
}

// .coc-estimates-body tr:hover td {
//     // background: $coolgray;
//     transition: background-color 0.2s ease;
// }

// .coc-estimates-select-action {
//     // margin-right: 110px;
//     text-align: center;
// }

// table.coc-estimates td.coc-estimates-expanded-estimate {
//     background: white;
//     padding-left: 90px;
//     cursor: initial;
// }

.coc-estimates-select-dropdown {
    position: absolute;
    background: white;
    width: 198px;
    padding: 10px;
    margin: 8px 0 0 -16px;
    border: 1px solid $coolgray;
}

.coc-estimates-select-option {
    padding: 4px 10px;
    cursor: pointer;
}

.coc-estimates-select-option:hover {
    background-color: $coolgray;
}

.coc-estimates-sortable-header:hover {
    // background: $coolgray;
    cursor: pointer;
}

/* Estimate Step Controls */
.estimate-controls {
    text-align: center;
    padding: 40px 0 40px;
}

.steps.estimate-controls {
    text-align: end;
}

button[type="reset"].estimate-previous-button {
    display: inline-block;
    margin: 0 20px;
}

// .estimate-finish-button {
//     // margin-left: 80px;
// }

button[type="submit"].estimate-save-button,
button[type="submit"].estimate-finish-button {
    display: inline-block;
    margin: 0 20px;
}

button[type="submit"].estimate-finish-button,
button[type="submit"].estimate-finish-button:hover {
    background-color: $white;
    border: 2px solid $krollgreen;
    color: $krollgreen;
    cursor: pointer;
}

/* Estimate */

.estimate-container {
    background: white;
}

.step-nav {
    background: #e9ecee;
    padding-left: 15px;
    box-shadow: 0 2px 3px -2px #a8b5bd;
}

.step-nav-item {
    display: inline-block;
    padding: 10px 20px;
    color: #a8b5bd;
    font-size: 14px;
    cursor: pointer;
}

.step-nav-item.active {
    color: #ee3124;
}

nav-item.active {
    color: #ee3124;
}

.step-nav-item.disabled {
    cursor: not-allowed;
}

.step {
    width: 60%;
    min-width: 600px;
    margin: auto;
    position: relative;
}

.step1 {
    width: 60%;
    min-width: 600px;
    position: relative;
    left: 10%;
}

.step-wide {
    width: 95%;
    min-width: 950px;
    margin: auto;
    position: relative;
}

.estimate-prompt {
    text-align: center;
    margin: 0 5%;
    padding: 20px 0 50px 0;
}

.trends-over-time-header {
    text-align: center;
    margin: 0 5%;
    padding: -1 0 50px 0;
}

.estimate-prompt span {
    font-size: 12px;
}

studies .step {
    width: 100%;
    padding: 16px 31px;
}

.study-nav>div {
    padding: 0;
}

.study-group {
    // padding: 10px;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    cursor: pointer;
    color: $krollblue;
    width: fit-content;
}

@media (min-width: 1720px) {
    .study-group {
        font-size: 21.1px;
        // font-size: 23.2px;
    }
}

.study-group.active {
    color: $krollblue;
    font-size: 23.2px;
}

.study-group:not(.active) {
    color: $coolgray;
    font-size: 21.1px;
}

.study {
    border-bottom: 1px solid $krollshadow;
}

.study .category {
    font-size: calc(10px + 0.2vw);
    font-weight: bold;
}

.study .title {
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    cursor: pointer;
    color: $coolgray;
}

.study .title.active {
    color: $krollgreen;
    // border-bottom: 5px solid $krollgreen;
}

.study .study-type {
    padding: 0 30px 0 0;
}

.study-instructions {
    text-align: center;
    font-size: 14px;
    margin-top: 30px;
    font-weight: bold;
    color: $coolgray;
}

.study-container:first-child,
.scenario-study-container:first-child {
    margin: 20px -15px 0 -15px;
}

.study-container,
.scenario-study-container {
    margin: 20px -15px 0 -15px;
}

.scenario-study-container equation {
    margin-top: 50px;
}

.study-container equation {
    margin-bottom: 50px;
}

.scenario-study-container equation:first-child {
    margin-top: 20px;
}

.scenario-study-container equation:last-child {
    margin-bottom: 60px;
}

.study-container .scenario-options {
    position: relative;
    // left: 50px;
    font-size: 14px;
    padding-top: 20px;
}

.study-equation-type {
    color: $krollblue;
    font-size: 1rem;
    font-weight: bold;
}

.study-container .scenario-option,
.study-container .scenario-divider {
    color: $krollgreen;
}

.study-container .scenario-divider {
    padding: 0 4px;
}

.study-container .scenario-option {
    cursor: pointer;
}

.study-container .scenario-option:hover {
    text-decoration: underline;
}

.scenario-study-button {
    width: auto;
    color: $krollgreen;
    font-size: calc(10px + 0.2vw);
    border: 0;
    cursor: pointer;
    margin-left: 5px;
    font-weight: 700;
    text-decoration: none;


    &:hover {
        text-decoration: none;
        color: $krollgreen;
    }
}

button.scenario-study-button {
    color: $white;
    background-color: $skyblue;
    padding: 4px;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $white;
    }
}

.scenario-study-container .scenario-options {
    position: relative;
    top: 16px;
}

.scenario-study-container .scenario-lever-state {
    text-transform: uppercase;
    font-weight: bold;
    color: $lightgray;
    text-align: center;
    margin-top: -10px;
    z-index: 1000;
    font-size: calc(12px + 0.1vw);
    // float: right;
    display: inline;
    margin-left: -10px;
}

.scenario-study-container .scenario-lever-state span {
    margin: 0 10px;
    cursor: pointer;
}

.scenario-study-container .scenario-lever-state span.active {
    color: $skyblue;
}

.scenario-study-container .scenario-lever-statebuildup3 span.active {
    color: $skyblue;
}

.scenario-rename input {
    border: 1px solid $coolgray;
    border-radius: 5px;
    width: 200px;
    padding: 2px;
    margin-right: 2px;
    float: left;
}

.scenario-rename .submit {
    color: $krollgreen;
}

.scenario-rename .cancel {
    color: $punch;
}

.scenario-rename i {
    font-size: 32px;
    position: relative;
    float: left;
    top: -2px;
    cursor: pointer;
    margin: 0 1px;
}

.equation {
    display: flex;
}

.equation-name {
    color: $krollshadow;
    font-weight: bold;
    font-size: 14px;
    // margin-bottom: 18px;
    display: inline-block;
}

equation .equation {
    display: flex;
    margin: 0;
    color: $krollshadow;
    // height: 3.4vw;
    position: relative;
    /* constrains equation-parameter "modal" */

}

.editor-active {
    height: 3.4vw;
}

.eq-element {
    text-align: center;
    flex: 1 1 auto;
}

.eq-element:nth-child(odd) {
    width: 16%;
}

.eq-element:nth-child(even) {
    width: 5%;
}

equation .editor-offset {
    z-index: -1;
}

equation button.clone {
    position: absolute;
    right: -6.5%;
    top: 18px;
    background: #13b5ea;
    color: white;
    width: 5vw;
    border: 0;
    font-size: 0.8vw;
    cursor: pointer;
    margin-right: -10px;
}

equation .editor {
    position: relative;
    width: 100%;
    border: 3px solid $skyblue;
    padding: 80px 20px 50px 20px;
    background-color: $white;
}

equation-parameter:first-child {
    color: $krollblue;

    i {
        display: none;
        visibility: none;
    }
}

.eq-var {
    position: relative;

    i {
        position: relative;
        top: -20px;
        float: right;
        color: $skyblue;
        font-weight: bolder;
    }

    .not-ready-warning {
        font-size: 16px;
        color: $punch;
        padding: 5px;
    }
}

.eq-value,
span.eq-element {
    font-size: calc(24px + 1vw);
    font-weight: bold;
}

.eq-info {
    position: absolute;
    top: -4px;
    right: -10px;

    .common-help-icon i {
        color: $krollshadow;
    }
}

equation-parameter .eq-var-active {
    background: $white;
    // color: white;
    position: relative;
    top: -24px;
    right: -18px;
    z-index: 10;
    box-shadow: 0px 1px 4px 1px $skyblue;
    transition: all 2.5s ease-in-out;
    border: 4px solid $skyblue;
    padding: 10px;
    font-weight: bold;

    .title {
        text-transform: uppercase;
    }

    i {
        position: relative;
        top: -10px;
        float: right;
    }

    i:hover {
        color: $skyblue;
        font-weight: bold;
    }

    &-edit {
        height: 20px;
    }

    button.save {
        background: $skyblue;
        border-radius: 5px;
        color: $white;
    }

    button.cancel {
        color: $krollshadow;
    }
}

equation-parameter .eq-var-active button.save.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

equation-parameter .title,
.equation-key .title {
    font-size: 0.76vw;

    @media (min-width: 1921px) {
        font-size: 0.7vw;
    }
}

.equation-key .title {
    text-transform: uppercase;
}

equation-parameter button {
    background: white;
    color: $krollgreen;
    width: 90%;
    font-size: 14px;
    margin-bottom: 5px;
    border: 0;
    cursor: pointer;
    font-weight: bold;
}

.equation-key {
    margin: 40px 0px;
    display: flex;
}

.equation-key .eq-element {
    font-size: 2.4vw;
    display: inline-block;
    color: $coolgray;
    opacity: 0.5;
    position: relative;
}

.equation-key .eq-element:first-child {
    color: $krollblue;
}

.equation-key help-icon {
    top: -13px;
    right: 0px;
    position: absolute;
}

results .step {
    width: 90%;
    padding-top: 24px;
}

size-premium-editor {
    display: block;
    width: 96%;
    margin: auto;
}

size-premium-editor table {
    width: 100%;
}

size-premium-editor table .target-decile-header {
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    padding: 0 20px;
    border-bottom: 1px solid $coolgray;
}

size-premium-editor table td {
    text-align: center;
}

size-premium-editor table td:first-child {
    text-align: left;
}

size-premium-editor .crsp-header h5 {
    float: left;
}

size-premium-editor .crsp-header span {
    font-size: 14px;
    margin-left: 10px;
    float: left;
}

size-premium-editor table .size-grouping-header {
    text-align: left;
    border-top: 1px solid $coolgray;
}

size-premium-editor table .padding20 {
    padding-top: 20px;
}

size-premium-editor .target-decile td {
    text-align: center;
}

size-premium-editor .selected {
    background: rgba(244, 117, 33, 0.28);
}

size-premium-editor .selectable:hover {
    background: rgba(244, 117, 33, 0.42);
    cursor: pointer;
}

size-premium-editor .target-decile:not(.selectable) td:last-child {
    color: transparent;
}

size-premium-editor .target-decile.selectable td:last-child {
    color: $krollblue;
}

size-premium-editor .target-decile.selectable td {
    font-weight: bold;
}

size-premium-editor table .capm {
    color: $krollgreen;
    font-weight: bold;
}

risk-premium-margin {
    display: block;
    padding: 0 30px;
}

risk-premium-margin h4 {
    text-align: left;
}

risk-premium-margin label {
    line-height: 40px;
    text-align: right;
}

risk-premium-margin .row {
    min-height: 60px;
}

risk-premium-margin input {
    width: 90%;
    border: 1px solid $coolgray;
    line-height: 40px;
}

risk-premium-margin .container {
    padding-top: 80px;
}

risk-premium-margin .years {
    font-weight: bold;
}

.risk-inputs {
    display: block;
    margin: 0 20px;
}

.risk-inputs td {
    width: 16.6%;
    text-align: right;
    color: #465661;
    padding-bottom: 12px;
}

.risk-inputs input {
    border: 1px solid $coolgray;
    width: 90%;
    padding: 6px 12px;
    text-align: right;
}

.risk-input-text {
    font-size: 12px;
    text-align: center;
    margin-top: 50px;
}

.risk-input-header {
    text-align: left;
    border-bottom: 1px solid $coolgray;
    margin-bottom: 8px;
    color: $coolgray;
}

.risk-input-company-type,
.risk-input-distressed {
    text-align: center;
}

.risk-input-company-type input,
.risk-input-distressed input {
    display: none;
}

.risk-input-company-type span,
.risk-input-distressed span {
    margin: 0 14px;
}

.risk-input-error {
    font-size: 16px;
    text-align: center;
    color: $error;
}

.year-editor {
    text-decoration-line: underline;
    cursor: pointer;
}

.zscore-inputs-container .common-input-name {
    justify-content: left;
    text-align: left;
}

/* Report */
.export {
    cursor: pointer;
}

/* Common Help Icon */
.common-help-icon {
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: -10px;
}

.common-help-icon i {
    color: $krollshadow;
    font-size: 18px;
    position: relative;
    cursor: pointer;
}

.common-help-icon .coc-help-pulse-inner {
    top: 0px;
    left: -4px;
}

.common-help-icon .coc-help-pulse-outer {
    top: -22px;
    left: -4px;
}

/* Common Collapsible */
.collapsible {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.collapsible:hover .title {
    text-decoration: underline;
}

.collapsible-region.collapsed {
    height: 0px !important;
}

/* Mask Directive */
.mask {
    position: absolute;
    top: 0;
    line-height: 42px;
    padding-left: 16px;
    pointer-events: none;

    .input-mask {
        visibility: hidden;
    }
}

.coc-help-pulse-inner,
.coc-help-pulse-outer {
    cursor: pointer;
    width: 22px;
    height: 22px;
    position: relative;
    background: rgb(20, 72, 127); //#92d560;
    border-radius: 50%;
    z-index: 10;
}

.coc-help-pulse-inner {
    box-shadow: 0 0 0 30px rgba(20, 72, 127, 0.5);
}

.coc-help-pulse-outer {
    box-shadow: 0 0 0 40px rgba(20, 72, 127, 0.5);
}

.coc-help-pulse-inner-animation-entrance {
    animation: pulse-inner 0.4s 1;
}

.coc-help-pulse-outer-animation-entrance {
    animation: pulse-outer 0.4s 1;
}

.coc-help-pulse-inner-animation-exit {
    animation: pulse-inner 0.4s 1;
    animation-direction: reverse;
}

.coc-help-pulse-outer-animation-exit {
    animation: pulse-outer 0.4s 1;
    animation-direction: reverse;
}

@keyframes pulse-inner {
    0% {
        box-shadow: 0 0 0 0 rgba(20, 72, 127, 0.5);
    }

    100% {
        box-shadow: 0 0 0 30px rgba(20, 72, 127, 0.5);
    }
}

@keyframes pulse-outer {
    0% {
        box-shadow: 0 0 0 0 rgba(20, 72, 127, 0.5);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(20, 72, 127, 0.5);
    }
}

/* Rename */
.coc-rename input {
    border: 1px solid $coolgray;
    border-radius: 3px;
    width: 200px;
    padding: 2px;
    margin-right: 2px;
    float: left;
}

.coc-rename .submit {
    color: $krollgreen;
}

.coc-rename .submit[disabled="true"] {
    color: $coolgray !important;
    cursor: not-allowed;
}

.coc-rename .cancel {
    color: $punch;
}

.coc-rename i {
    font-size: 32px;
    position: relative;
    float: left;
    top: -2px;
    cursor: pointer;
    margin: 0 1px;
    z-index: 1;
}

.coc-rename .cancel-background {
    background: white;
    height: 21px;
    width: 23px;
    display: block;
    position: relative;
    margin: -26px 0px 0px 2px;
    left: -30px;
    float: left;
    top: 29px;
}

.coc-rename .submit-background {
    background: white;
    height: 21px;
    width: 21px;
    display: block;
    position: relative;
    margin: -26px 0px 0px 2px;
    left: -61px;
    float: left;
    top: 29px;
}

/* Modals */
/* TODO: Preface all this to escape Bootstrap styling */
//.modal-header {
// background: #d0d7dd;
//}

.modal-header {
    justify-content: space-between !important;
}

.modal-title {
    font-weight: bold;
    font-size: 20px;
    color: $krollblue;
}

.modal-dismiss {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: $krollshadow;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.modal-dialog {
    margin: 15% auto 30px auto;
}

.modal-lg .modal-body {
    margin: 30px 50px 0 50px;
}

.hide-highchart-button {
    .highcharts-exporting-group {
        display: none;
    }
}


.modal-body {
    text-align: center;
    padding: 0;
    margin: 30px 15px 0 15px;
}

.modal-footer {
    border-top: none;
    // display: block;
    // padding: 0;
    margin: 20px 0 10px 0;
    flex-direction: row-reverse;
    float: right;
}

.modal-submit {
    padding: 16px 32px;
    // margin: auto !important;
    /* Override bootstrap */
}

.modal-cancel {
    padding: 12px 0;
    // margin: 10px auto !important;
    /* Override bootstrap */
}

.coc-critical-error-icon {
    font-size: 60px;
    margin-right: 20px;
}

.coc-critical-error-text {
    font-size: 18px;
    width: 70%;
    display: inline-block;
}

/* Help Overlay */
.coc-help-overlay {
    position: fixed;
    z-index: 15000;
    color: $white; //#262631;
    background: rgb(20, 72, 127); //#92d560;
    width: 100%;
    bottom: 0;
    height: 40%;
    overflow: auto;
    box-shadow: 0px 0px 10px 0px $coolgray;

    .helpicon-color {
        color: $krollgreen !important;
    }
}

@keyframes slideout {
    from {
        bottom: -50%;
    }

    to {
        bottom: 0;
    }
}

.coc-help-overlay-entrance {
    animation: slideout 0.4s 1;
    animation-timing-function: ease;
}

.coc-help-overlay-exit {
    bottom: -50%;
    animation: slideout 0.4s 1;
    animation-timing-function: ease;
    animation-direction: reverse;
}

.coc-help-close {
    font-size: 28px;
    position: sticky;
    left: calc(100% - 30px);
    top: 12px;
    cursor: pointer;
}

.coc-help-title {
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
    font-size: 22px;
}

.coc-help-action-button button {
    box-shadow: none;
    margin: 20px auto;
}

.coc-help-text {
    text-align: left;
    font-size: 18px;
    margin: 20px 10% 0 10%;

    a:hover {
        color: $krollgreen;
    }
}

@media (max-width: 1240px) {
    .coc-help-text {
        margin: 20px 3% 0 3%;
    }
}

/* Loading Overlay */
.loading-overlay {
    width: 100%;
    height: 100%;
    background: black;
    position: fixed;
    z-index: 12000;
    opacity: 0.4;
}

.loading-overlay-spinner {
    z-index: 12001;
    top: 50%;
    left: 50%;
    position: fixed;
}

.loading-overlay-spinner img {
    width: 100%;
    margin-top: -50%;
    margin-left: -50%;
}

/* Errors */
.coc-input-error {
    border: 1px solid $error !important;
    background: rgba(255, 0, 0, 0.1) !important;
    outline: none;
}

.coc-error {
    color: $error;
    font-style: italic;
    visibility: hidden;
}

.show {
    visibility: visible;
}

.invalid-input {
    margin-top: 16px;
}

/* Section */
.coc-section {
    padding: 16px;
}

/* Flex */
.flex-row {
    display: flex;
    flex-direction: row;
    margin: 0 -15px;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Pdf Print Button */
.coc-print-pdf {
    float: right;
    display: none;
}

.coc-print-pdf i {
    font-size: 36px;
    margin: auto;
    color: $coolgray;
    cursor: pointer;
    float: right;
    color: $krollgreen;
}

.coc-print-pdf.show {
    display: block;
    margin: auto;
    flex-grow: 8;
    padding-right: 80px;
}

/* Pdf Export Styles */
.prepare-export .collapsible-region.collapsed {
    height: 100% !important;
}

.prepare-export button.pdf-export,
.prepare-export .equation-toggle,
.prepare-export .graph-box,
.prepare-export .exclude-from-pdf {
    display: none !important;
}

.page-template {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.page-template .header {
    position: absolute;
    top: 0;
    height: 20px;
    width: 100%;
    background-color: $krollblue;
}

.page-template .logo {
    position: absolute;
    top: 36px;
    right: 30px;
    background: transparent;
    z-index: 10;
    width: 300px;
}

.page-template .footer {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    border-top: 1px solid $coolgray;
    text-align: center;
    color: $coolgray;
}

.page-template .watermark {
    font-weight: bold;
    font-size: 800%;
    text-align: center;
    margin-top: 30%;
    color: $coolgray;
    opacity: 0.1;
    transform: rotate(-35deg) scale(1.7, 1.5);
}

/* Range Key */
.coc-range-key {
    flex: 1;
    text-align: center;
    font-size: 12px;
    position: relative;
    top: 10px;
    left: 16px;
}

.coc-range-key .key-item {
    margin-right: 20px;
}

.coc-range-key .key-item span {
    border: 5px solid #ee3124;
    border-radius: 5px;
    display: inline-block;
    margin-right: 4px;
}

/* Range Chart */
.coc-range-chart {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.coc-range-chart .title {
    text-align: center;
    font-size: 1.2vw;
    margin: 6px 0 10px;
}

.coc-range-chart .chart-container {
    transform-origin: left;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.coc-range-chart .chart {
    width: 100%;
}

.coc-range-chart .body {
    height: 40px;
}

.coc-range-chart .background {
    background-color: $coolgray;
    height: 4px;
}

.coc-range-chart .ticks {
    position: relative;
    top: -13px;
}

.coc-range-chart .tick {
    border-right: 3px solid $coolgray;
    height: 24px;
    width: 7.1%;
    float: left;
}

.coc-range-chart .label {
    font-weight: bold;
    font-size: 14px;
}

.coc-range-chart .bullits {
    position: relative;
    top: -30px;
}

.coc-range-chart .bullit-container {
    position: absolute;
    z-index: 10;
    display: inline-block;
}

.coc-range-chart .bullit-label {
    font-size: 12px;
    position: relative;
}

.coc-range-chart .bullit-label.left {
    left: -30px;
    top: 19px;
}

.coc-range-chart .bullit-label.right {
    left: 30px;
    top: 19px;
}

.coc-range-chart .bullit {
    border: 10px solid $krollblue;
    border-radius: 10px;
    width: 0px;
    margin: auto;
}

/* Legal */
.legal-background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.legal-splash {
    top: 10%;
    background: white;
    padding: 30px;
    box-shadow: 1px 1px 2px 1px black;
    margin: 0 auto;
    width: 75%;
    position: absolute;
    left: 50%;
    margin-left: -37.5%;
}

.accept-info {
    display: none;
}

.accept-info~label {
    width: 24px;
    height: 24px;
    display: inline-block;
    border: 2px solid black;
    border-radius: 8px;
    position: relative;
}

.accept-info~label .checked {
    display: none;
}

.accept-info:checked~label .checked {
    display: inline-block;
    color: #1a82ea;
    font-size: 20px;
    position: absolute;
    top: -5px;
    left: 0px;
}

.terms-line {
    line-height: 34px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

form[name="acceptForm"] a {
    font-weight: bold;
    color: $krollgreen !important;
    text-decoration: underline;
}

.showDataTable {
    margin-left: 20px;
    color: $krollgreen;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: calc(10px + 0.2vw);

    i {
        padding-left: 10px;
        font-size: 16px;
        color: $coolgray;
    }
}

p-dialog {
    h6 {
        text-align: center;
    }

    table {
        h6 {
            font-size: 14px;
            font-weight: bold;
            padding: 5px 0;
            margin-top: 10px;
        }
    }
}

.ui-dialog {
    .ui-dialog-titlebar {
        text-align: center;

        .ui-dialog-title {
            font-size: 18px;
            font-weight: normal;
        }
    }
}

.view-companies {
    color: $coolgray;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: -30px;
    text-align: right;
    margin-right: -242px;
    margin-top: -24px;
    float: right;
}

a.link {
    cursor: pointer;
}

a.link:hover {
    text-decoration: underline !important;
}

.table-margin {
    margin-bottom: 14px;
}

.products-box {
    margin-left: 15px;
    margin: 0 120px 0 40px;
}

.news-table {
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th,
    td {
        text-align: left;
        padding: 8px;
    }

    table tr:first-child {
        font-weight: bold;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background-color: $thinlightgray;
    }
}

.container-fluid {
    padding-top: 25px;
    font-size: 15px;
}

// tooltip
.tooltip {
    display: inline-block;
    position: relative;
    text-align: left;
    opacity: 1;
    z-index: inherit;
}

.tooltip .top {
    min-width: 200px;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 10px 20px;
    color: #ffffff;
    background-color: #31535f;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    visibility: hidden;
    transition: opacity 0.8s;
}

.tooltip:hover .top {
    visibility: visible;
    opacity: 1;
}

.tooltip .top i {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
}

.tooltip .top i::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #3c4b54;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.cool-grey {
    color: rgb(167, 168, 169);
}

.tot-toggle {
    padding: 0 20px;
}

.tot-toggle-lable {
    color: $coolgray;
    cursor: pointer;

    &.active {
        font-weight: bold;
        color: $skyblue;
    }
}


.industry-benchmarking.tot-toggle .tot-toggle-lable,
.crp-tot.tot-toggle .tot-toggle-lable {
    color: $coolgray;
    cursor: pointer;
    background: #FFFFFF;
    color: $krollshadow;
    padding-top: 7px;
    padding-bottom: 7px;
    border-style: solid;
    border-color: #D9D9D9;
    border-width: thin;

    &.graph {
        padding-left: 30px;
        padding-right: 10px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &.table {
        padding-right: 30px;
        padding-left: 10px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &.active {
        border-style: solid;
        border-color: #14487F;
        border-width: thin;
        background: #14487F;
        color: #FFFFFF;

        &.graph {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        &.table {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}

.print-pdf {
    display: inline;

    i {
        font-size: 36px;
        margin: auto;
        color: #84939e;
        cursor: pointer;
        float: right;
        color: $krollgreen;
        padding: 0 20px 20px;
    }
}

.ms-Icon {
    cursor: pointer;
    margin-left: 1%;
    color: #4C9FC8;
}

.margin-auto {
    margin: auto;
}

.no-padding {
    padding: 0;
}

//Animation Icon
i.ms-Icon:hover {
    transition: 0.5s;
    transform: Scale(1.5);
}

.alert-not-available,
.alert-not-available:hover {
    transform: rotate(180deg) !important;
    font-size: 2rem;
    padding: 0 20px;
    color: $punch;
    cursor: inherit;
}

.information,
.information:hover {
    transform: none !important;
    font-size: 2rem;
    color: $skyblue;
    margin-right: 20px;
    cursor: inherit;
}

.pro-text-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.hide-widget-content {
    .ui-widget-content {
        overflow: hidden;
    }

    .highcharts-container {
        left: 115px !important;
    }

    .pi-window-maximize {
        display: none !important;
    }
}

.row {
    .fa-arrows-alt {
        float: right;
        padding-top: 28px;
    }
}

@media screen and (max-width: 1440px) {
    .common-help-icon {
        width: 14px;

    }
}


.align-text {
    text-align: right;
}


.margins {
    margin-right: 15px;
}


@media screen and (max-width: 1440px) {
    .common-help-icon {
        width: 14px;

    }
}


.pi {
    font-size: small;
    font-weight: bold;
    color: $krollshadow;
}

.comprblCompany {
    .p-dialog .p-dialog-content {
        padding-left: 0rem !important;
        padding-top: 0px !important;
        display: grid;
    }
}



.IndustryList {
    .ui-dialog-content {
        overflow: hidden;
    }
}

.placeholder {
    background-color: transparent !important;
}


// override Bootstrap

hr {
    margin-left: 0%;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    opacity: 100;
}

// p-footer {
//     justify-content: right;
// }

body .p-dialog .p-dialog-header {
    background-color: $white;
    text-align: left;
    border-bottom: 1px solid #c8c8c8;
    color: #333;
    padding: 0.8em;
    font-weight: 700;

    .p-dialog-title {
        font-family: "nunito-sans";
        font-weight: 700;
        font-size: 18px;
        color: $krollblue;
    }

    .p-dialog-header-icon {
        color: #848484;
    }
}

.p-dialog .p-dialog-content {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;

}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}


.p-dialog-footer {
    padding: 0.3rem 1rem;
}


.trends-over-time-modal .p-dialog .p-dialog-content {
    top: 56px;
}


.p-dialog {
    max-height: none !important;
}

.p-dialog .p-dialog-content {

    padding: 0.5rem !important;
    padding-left: 0.8rem !important;
}


// .row_width {
//     width: auto;
// }

// .comparablecmp,
// .row>* {
//     width: auto !important;
// }

// .modalfont {
//     .p-dialog .p-dialog-content {
//         font-size: 5px !important;
//     }
// }

.fullScreenComp {
    .highcharts-axis-labels {
        font-family: 'nunito-sans';
        font-size: 12px;
    }
}

.signUpCss {
    font-family: 'nunito-sans';
}

.signUpCss .p-dialog-header-maximize-icon {
    display: none;

}


.signUpCss .p-dialog .p-dialog-header {
    padding: 0.4rem;
}

.warnHeader {
    position: relative;
    top: 7px;
}


.confirmBtns {
    margin-left: 20px;
    color: white;
    line-height: 44px;
    width: 90px !important;
    font-size: 18px;
}

.yesBtn {
    background-color: $krollblue;
}

.infocss {
    padding: 15px;
}

.mgbtm {
    margin-bottom: 20px;
}


.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.6s;
}



.logo {
    height: 32px;
    margin: 16px;
    margin-left: 24px;
    margin-bottom: 35px;
}

nz-header {
    background: #fff;
    padding: 0;
    border-bottom-color: #D4D4D4;
    border-bottom-style: solid;
}

nz-content {
    margin: 0 16px;
    height: 100%;
}


.inner-content {
    padding: 24px;
    background: #FBFBFB;
    min-height: 360px;
    min-height: 100%;
    padding-left: 52px !important;
    position: relative;

}





.hidelOgo {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.9s linear;
}

// .ngSiderCustom {
//     background: #001424 !important;

// }


.menucolapseIcon {
    position: absolute;
    bottom: 124px;
    left: 14px;
}

.ant-layout-header {
    background-color: white !important;
    height: 60px !important;
}



nz-content {
    height: "100vh";
    overflow: "auto";
    margin: 0 !important;

}

.mainlyout {
    height: 100vh;
}

.ant-layout-content {
    overflow: auto;
    height: 100%;
    background-color: #FFFFFF !important;

}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #182632 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $krollgreen !important;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: $krollgreen !important;
}






nz-sider {
    overflow: auto;

}

.ngSiderCustom::-webkit-scrollbar {
    width: 0 !important
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
    font-size: 20px !important;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    font-size: 18px !important;
}


.ant-menu-item .anticon+span,
.ant-menu-submenu-title .anticon+span {
    margin-bottom: -6px;
}




.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    white-space: normal;
    line-height: 18px !important;
    font-size: 16px !important;
    height: 50px !important;
    word-wrap: break-word;

}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
    font-size: 15px !important;

}




/* Rectangle 103 */



/* Grey / grey-1 */



.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
    /* For tabs border */
    border-color: gray;
}

.ant-tabs-top>.ant-tabs-nav::before {
    /* For the line to the right and close to the tabs */
    border-color: gray;
}

.ant-tabs>.ant-tabs-nav {
    /* So that there is no gap between the content and tabs */
    margin-bottom: 0 !important;
}

.ant-tabs-content-holder {
    padding: 15px;
    border: 1px solid gray;
    /* Border for the content part */
    border-top: transparent !important;
    background: #FFFFFF;
    /* Grey / grey-5
    
    Border
    box-sizing: border-box;
    */
    border: 1px solid #D9D9D9;
    /* Form boxes */
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 5px 5px;

}


.custborders {
    margin-top: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 3px;
    padding: 0px !important;
}




.optiontxt {
    font-style: normal;
    font-size: 13px;
    width: 100%;
    padding: 1px;
}








.tableFixHead {
    margin-top: 10px;
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    height: 310px;
    // border-bottom: 1px solid #EDEDED;

    table {
        border-collapse: collapse;

        /* make the table borders collapse to each other */
        width: 100%;
    }

    /* gives an initial height of 200px to the table */
}




.tableFixHead thead th {
    position: sticky;
    /* make the table heads sticky */
    top: -0.2px;

    border: 1px solid #ECEEEF !important;

    /* table head will be placed from the top of the table and sticks to it */
}

.second-row {
    position: sticky;
    top: 30px;
}


.tableFixHead table {
    border-collapse: collapse;

    /* make the table borders collapse to each other */
    width: 100%;
}

.tableFixHead th,
.tableFixHead td,
.viewData_tbl td,
.subscriptiondtils_tbl td {
    padding: 8px 16px;
    border: 1px solid #ccc;
}

.viewData_tbl tr :not(:first-child) {
    text-align: right;
}

.viewData_tbl td :not(:first-child) {
    text-align: right;
}

.highFinacialTabel {
    text-align: left !important;
}

.highFinacialTabel tr :last-child {
    text-align: right !important;
}

.highFinacialTabel td :last-child {
    text-align: right !important;
}



.tableFixHead::-webkit-scrollbar,
.assumption-container::-webkit-scrollbar {
    width: 8px !important;
}

/* Track */
.company-tbl::-webkit-scrollbar-track,
.tableFixHead::-webkit-scrollbar-track,
.assumption-container::-webkit-scrollbar-track {
    background: #ffffff !important;
}

/* Handle */
.company-tbl::-webkit-scrollbar-thumb,
.tableFixHead::-webkit-scrollbar-thumb,
.assumption-container::-webkit-scrollbar-thumb {
    border-radius: 37px !important;
    background: #D9D9D9 !important
}

.company-tbl::-webkit-scrollbar {
    height: 10px !important;
    width: 10px !important;
}



.linkclrgrn {
    color: $krollgreen;
}

.bold-kroll-blue {
    color: $krollblue;
}


.btnviewsubscribe {

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $krollgreen;


}

.pleft {
    padding-left: 3px;
}



.dashboard-cards {
    border: 1px solid gray;
    /* Border for the content part */
    border-top: transparent !important;
    background: #FFFFFF;
    /* Grey / grey-5
    
    Border
    box-sizing: border-box;
    */
    border: 1px solid #D9D9D9;
    /* Form boxes */
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
}


.main-content {
    padding: 20px;
    padding-left: 42px;

}

.main-content table {
    width: 100%;
}

.main-content table tr td:first-child {
    background: #F6F6F7;

}



.newsinsights {
    background: #FFFFFF;
    border-bottom: 1px solid #ECEEEF;

    div {
        color: $krollblue;
        font-weight: 700;
        font-size: 18px;

        a {
            cursor: pointer;
        }
    }

    h6 {
        font-style: italic;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #7A7A7B;

        span {
            float: right;
        }
    }
}

.custcolmpadding {
    padding-left: 0px !important;
}

.mrgntop {
    margin-top: 10px;
}






// .tblSizePremium tbody tr:nth-child(even) {
//     background: #ddd;
// }


.dshboardHead {
    height: 50px;
    background: #FFFFFF;
    border-bottom: 1px solid #ECEEEF;
    padding: 10px;
    color: $krollblue;
    font-weight: 700;
    font-size: 18px;
    padding-left: 15px !important;
}

.dshboardHeadSubscription {
    height: 50px;
    background: #FFFFFF;
    border-bottom: 1px solid #ECEEEF;
    padding: 10px;
    color: $krollblue;
    font-weight: 700;
    font-size: 18px;
    padding-left: 44px !important;
}

.dshboardHeaderRow {
    padding: 10px;
    color: $krollblue;
    font-weight: 700;
    font-size: 22px;
    padding-left: 18px !important;
    padding-top: 0px !important;
}

.dashboardHead {
    padding: 10px;
    color: #4D4D4F;
    font-weight: 700;
    font-size: 22px;
    padding-left: 18px !important;
    padding-top: 0px !important;
}

.lblsubscription {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    width: 180px;
}

.subscriptionDetails {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

}

.circlegreen {
    width: 11px;
    height: 11px;
    background: #43B049;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 8px;

}

.circlered {
    width: 11px;
    height: 11px;
    display: inline-flex;
    background: #A50A35;
    border-radius: 50%;
    margin-right: 8px;

}

.subscriptiondtils_tbl tr:first-child td:nth-child(2),
.subscriptiondtils_tbl tr:first-child td:nth-child(3) {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    border-right: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

}



.tblResources th,
.tblBuildup th {
    height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    padding: 8px 16px;
}

.tblResources td,
.tblBuildup td {
    padding: 8px 16px;
}

.tablepaddingbuires {
    padding: 15px;
}

.cocViewInputs {
    background-color: #FBFBFB !important;
}

.cocFooteText {
    text-align: center;
}

.cocFooter {
    padding: 10px 50px !important;
    color: rgba(0, 0, 0, .85);
    font-size: 12px !important;
    background: #FBFBFB !important;
    height: 48px;
    bottom: 0;
    width: 100%;
    line-height: 1.4 !important;
}

.custrow {
    height: 45px;
    background: #FFFFFF;
    border-bottom: 1px solid #ECEEEF;
    padding: 13px;
}




.borderdiv {
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #ECEEEF;
    padding: 13px;
}


.excel_export {
    background: #FFFFFF;
    border: 1px solid #DBDBDC;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4D4D4F;
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 4px;
    padding: 6px;
    float: right;

    :hover {
        cursor: pointer;
    }
}


.export-icon {
    padding-right: 4px;
}

.main-export-icon {
    svg path {
        fill: #FFFFFF !important;
    }

    svg {
        margin-bottom: 4px;
    }
}

.bench-export-icon {
    svg path {
        fill: #FFFFFF !important
    }

    svg {
        margin-bottom: 4px;
    }
}


.main-excel_export {
    float: right;
    background-color: #14487F;
    border-radius: 4px;
    color: white;
    padding: 6px;

    :hover {
        cursor: pointer;
    }
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

.renewbg {

    background: #ECF7ED;
    border: 1.29px solid #ECEEEF;
}


.inacvtivebg {
    background: #F5F5F5;
    border: 1.29px solid #ECEEEF;
}

.btnrenactiv-css {
    display: inline-flex;
    background: #14487F;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    float: right;
    text-align: center;

    :hover {
        cursor: pointer;
    }

}

.btnrenactiv-css span {
    padding: 2px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500 !important;
}





.btnrenRenew-css {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    color: #4D4D4F;
    display: inline-flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    float: right;
    text-align: center;

    :hover {
        cursor: pointer;
    }

}

.btnrenRenew-css span {
    padding: 2px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    width: 82px;
    font-weight: 500 !important;
}

.subscriptiondtils_tbl tbody tr:nth-child(2) td:not(:first-child) div {
    font-weight: 700;
}


// supplyData css


.heightcss {
    height: auto;
    max-height: 520px;

    table.fixed {
        table-layout: fixed;
        border-spacing: 0px;
        border: 1px solid #ECEEEF;
        border-collapse: separate;
    }

    table.fixed td {
        overflow: hidden;
        padding: 5px;
    }

    table.fixed th {
        padding: 5px;
        white-space: pre-line;
        border: 1px solid #ECEEEF;
    }

    table.fixed tr th:not(:first-child) {
        text-align: right;
    }

    table.fixed tr td:not(:first-child) {
        text-align: right;
    }

}


.mtop {
    margin-top: 8px;

}

.supplcombomrgn {
    margin-left: 13px;
}

.supplcombomrgn .common-input-line-stack {

    margin-top: -20px !important;

}


.custfontsuppdatatitle {
    /* font-style: normal; */
    font-weight: 600;
    font-size: 18px;
    /* line-height: 22px; */
    color: #14487F;
}

.heading-font-style {
    font-weight: 600;
    font-size: 16px;
    color: $krollblue;
}


.dataasofcs {
    color: #4d4d4f;
    font-size: 16px;
    font-weight: 500;
}

.headerSupp {
    word-wrap: break-word;
}


.supplylink {
    float: right;
    margin: 08px 0px 8px 0px;
}

.size-premium-content {

    table {
        width: 100%;
    }
}

.pedingrightlink {
    padding-right: 8px;
}

.suppexport {
    margin-left: auto;
    margin-right: 7px;
}

.custom-td {
    text-align: center !important;
    font-size: 14px !important;
}

.posstyl {
    position: absolute;
    right: 3px;
    z-index: 1;
    cursor: pointer;

}

// .headerside {
//     color: $krollblue;
// }

.framestyle {
    justify-content: center;
    display: flex;
}


.headerstyle {
    color: #14487f;
    font-weight: 700;
    font-size: 22px;
    padding-top: 0px !important;
}

.frameSizeheatMap {
    width: 1300px;
    height: 610px;
}


@media only screen and (min-width:1700px) {
    .frameSizeheatMap {
        width: 1300px;
        height: 610px;
    }
}

@media only screen and (min-width:2000px) {
    .frameSizeheatMap {
        width: 1800px;
        height: 750px;
    }
}

@media only screen and (min-width:2500px) {
    .frameSizeheatMap {
        width: 2000px;
        height: 1050px;
    }
}

/*--Common css for Coc-Inputs---*/

.custfont {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #14487F;
}






.tblregressequheight {
    height: auto !important;
}

.tablepadding {
    padding: 10px;
}

.dataspace>* {
    margin-bottom: 30px
}



.decileChar th:first-child {
    width: 25px;
}

.centeralign {
    text-align: center;
}

.centernodata {
    text-align: center;
    border: none;

}

.no-data-avail {

    font-weight: 600;
    color: #A50A35;
    font-size: 15px;

}

.beta-msg {
    color: #4C9FD8;
    font-size: 12px;
    padding-left: 10px;

}

.lock-icon {
    float: right;
    margin-top: 5px;
    ;
}





.indu-summry-tbl thead th,
.indu-summry-tbl tbody td {
    border-collapse: collapse;
    border: 1px solid #ECEEEF;
    padding: 8px 16px;

}

.indu-summry-tbl thead th {
    background: #F6F6F7;
    height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;

}

.indu-summry-tbl tbody td {
    background: #FFFFFF;
    height: 25px;
    color: #4D4D4F;
    font-weight: normal;
}

.indu-summry-tbl tbody td:first-child {
    font-size: 14px;
    font-weight: 700;
}

.indu-summry-tbl thead tr:nth-child(2) th {
    text-align: right;
    padding-left: 20px;
}

.indu-summry-tbl tbody td:not(:first-child) {
    text-align: right;
}

.indu-summry-tbl .main-headers {
    text-align: center;
    background-color: white;
    font-size: 14px;
    font-weight: 700;
}


.side-by-side {
    display: inline-block;
    width: 20%;
    margin-right: 10%;
}

.table-row {
    display: block;
}

.table-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.flex-grow {
    flex: 0 0 100%;
}

.metric-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

// .tear-sheet-content
// .ant-tabs-tab-btn {
//     width: 230px;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

.page-header-title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #14487F;
    margin-bottom: 0px;
}

.add-industrie-btn {
    background-color: #14487F;
    border-radius: 4px;
    color: white;
    padding: 8px 10px 8px 10px;
    font-size: 15px;

    :hover {
        cursor: pointer;
    }

    span:nth-child(2) {
        margin-left: 10px;
        margin-bottom: 1px;
    }
}

.add-industry-bench {
    display: flex !important;
    align-items: center;
    margin-left: 25px;

    div div:nth-child(1) {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 5px;
    }

}


.btn-industry {
    height: 32px;
    min-width: 116px !important;
    padding: 0px !important;
    font-size: 14px;

}


.selected-industries-card {
    margin-left: 10px;
    margin-top: 5px;
    border-radius: 2px;
    border: none;
    padding: 3px 10px 5px 10px;
    font-size: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    background-color: transparent;
}

.industry-excel {
    border-radius: 4px;
    height: 34px;
    padding: 7px;
    bottom: 4px;
    border: 1px solid #a7a8a9;

    background-color: #14487F;
    border-radius: 4px;
    color: white;

    :hover {
        cursor: pointer;
    }
}

.bench-excel-view-more {
    justify-content: end;
    padding-right: 16px;

}

.no-indstry-avil {
    color: $red;
    line-height: 72px;
    text-align: center;
    font-size: 17px;
}

.no-industry-select {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    font-size: 20px;
    font-weight: 400;
    color: $krollshadow;
}


.cmpnyExport {
    float: right;
    cursor: pointer;
}

.cmpanyrow {
    padding: 10px 10px 10px 0px
}

.lbltext {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
}

.industry-tittle {

    font-weight: 700;
    font-size: 30px;
    color: #003057;
    margin-bottom: 0px;
    padding: 15px 0px 15px 0px
}

.tbl-header {
    font-style: normal;
    font-weight: 500;
    color: #14487F;
}



.input-data-tbl tbody td:first-child {
    text-align: right;
    font-weight: 500;

}

.company-list {

    .ant-table-content {


        table thead tr th .ant-table-column-sorter-inner .active {
            color: $krollblue !important;
        }

        table thead tr th .ant-table-column-sorter-inner i {
            color: #949495 !important;
        }


        table thead tr th {
            border-top: none !important;
            position: sticky !important;
            top: -0.35px !important;
            z-index: 2;
            background: #F6F6F7 !important;
            height: 30px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            font-size: 13px !important;
        }

        table {
            border-collapse: collapse !important;
        }

        table {
            border-top: none !important;
        }

        table tbody tr td {
            padding: 8px 16px !important;
            border: 1px solid #ccc !important;
        }

        table thead tr th {
            padding: 8px 16px !important;
            border: 0px 1px 1px 1px solid #ccc !important;
        }


    }

    .ant-table {
        max-height: 310px !important;
    }
}

.estimate-common-tbl {
    .ant-table-content {
        table thead tr th:first-child {
            width: 300px !important;
        }

        table thead tr th:nth-child(2) {
            width: 150px !important;
        }

        table thead tr th:last-child,
        table thead tr td:last-child {
            text-align: center;
        }

        table thead tr th:nth-child(3) {
            width: 120px !important;
        }




        table {
            border-collapse: collapse !important;
        }

        table thead tr th {
            padding: 12px !important;
            border: 1px solid #ECEEEF !important;
            height: 19px;
            background-color: #F6F6F7;
            font-weight: 700;
            font-size: 14px;


        }

        table tbody tr td {
            background: #FFFFFF;
            padding: 12px !important;
            height: 19px;
            border: 1px solid #ECEEEF;
        }

    }
}

.us-estimates-tbl {
    .ant-table-content {
        table thead tr th:nth-last-child(2) {
            width: 400px !important;
        }
    }
}

.input-rename {
    background: #FFFFFF;
    border: 1px solid #717172;
    border-radius: 4px;
    padding: 2px;
    width: 100%;



}

.text-rename {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #14487F;


}

.text-rename:hover {
    cursor: pointer;
}




.estimate-name-cell {
    display: flex;
    justify-content: space-between;

}

.icon-check-cancel {

    span {
        font-size: 18px;
    }

    span:first-child {
        margin: 0px 10px 0px 3px;
    }

    span:hover {
        cursor: pointer;
    }
}

.action-items {

    span:hover {
        cursor: pointer;
    }

    span {
        font-size: 16px;
    }

}

.action-width {
    width: 130px;
}

.sectionHeader {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #7A7A7B;
}

.common-main-btn {
    background-color: #14487F;
    border-radius: 4px;
    color: white;
    padding: 6px 8px 4px 8px;
    font-size: 15px;

    :hover {
        cursor: pointer;
    }

    .icon {
        svg path {
            fill: #FFFFFF !important;
        }

        svg {
            margin-bottom: 5px !important;
        }
    }
}

.estimate-create-icon {
    margin-right: 8px;
}

.create-estimate {
    display: flex !important;
    justify-content: flex-end;
}

.common-section {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 25px 15px 25px 15px
}

.disabled-div {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.section-header {
    color: #14487f;
    font-size: 15px !important;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 4px;
}

.zero-top-pad {
    padding-top: 0px;
}

.color-krolgreen {
    color: $krollgreen;
}

.cmp-info-table thead tr th,
.cmp-info-table tbody tr td {
    text-align: left !important;
}