@import '../css/colors.scss';

.dashboard-content {
    width: 100%;
    flex: auto;
    display: flex;
    flex-flow: column;
    margin-top: -20px;

    .top,
    .all-module,
    .my-module {
        padding: 0px 30px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .top {
        h1 {
            color: #465661;
            font-size: 28px;
            font-weight: normal;
            margin-top: 30px;
            font-family: 'nunito-sans';
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 24px;
            color: #6b7881;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .dashboard-content-header {
            margin-bottom: 30px;
        }
    }

    .all-module {

        // background-color: #eceeef;
        h1 {
            font-size: 22px;
            margin-top: 30px;
            margin-bottom: 30px;
            font-family: 'nunito-sans';
        }
    }

    .my-module {
        h1 {
            font-size: 22px;
            margin-top: 30px;
            margin-bottom: 30px;
            font-family: 'nunito-sans';
        }
    }

    .span1 {
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .bookmarks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 1200px;

        .bookmark-wrapper {
            width: 23.20819%;
            height: 380px;
            margin: 0 10px 40px 0px;
        }

        .bookmark {
            position: relative;
            background: white;
            width: 100%;
            height: 380px;
            cursor: pointer;
            transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
                border 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);

            .header-image {
                position: relative;
                height: 175px;
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            .icon {
                width: 20px;
                color: #13b5ea;
                position: absolute;
                bottom: 2px;
                right: 14px;
                font-size: 30px;
            }

            .icon-calendar {
                width: 20px;
                color: grey;
                position: relative;
                padding-right: 2px;
                padding-left: 10px;
                font-size: 20px;
            }

            .icon-location {
                width: 20px;
                color: grey;
                position: relative;
                padding-right: 2px;
                padding-left: 10px;
                font-size: 20px;
            }

            // example of container classes: bookmark insight-video
            &.insight-video:hover {
                .icon-play {
                    &::after {
                        transform: scale(4);
                        opacity: 0;
                    }
                }
            }

            // Example of usage
            // <Icon iconName="TriangleSolidRight12" className='icon icon-play' onClick={() => { }} />
            .icon-play {
                display: flex;
                justify-content: center;
                align-items: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 4.5rem;
                width: 4.5rem;
                font-size: 1.7rem;
                color: #eb3023;
                z-index: 10;
                margin-top: -100px;
                margin-left: 5px;

                &::after {
                    content: "";
                    position: absolute;
                    left: -4%;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    border-radius: 10rem;
                    background: #fff;
                    transition: all 0.2s;
                    opacity: 0.8;
                    z-index: -1;
                }
            }

            .text {
                padding: 20px;
                height: 205px;
                font-family: 'nunito-sans';

                h2 {
                    font-size: 0.9rem;
                    font-family: 'nunito-sans';
                    font-weight: 500;
                    letter-spacing: 0;
                    color: #465661;
                    overflow: visible;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                }

                p {
                    font-size: 0.9rem;
                    color: #465661;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        h3 {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 1200px;

        .card-wrapper {
            flex-shrink: 0;
            max-width: 25.10%;
            min-height: 150px;
            max-height: 150px;
            margin: 0 -0.5px 40px -12px;
        }
        .card {
            position: relative;
            background: white;
            width: 100%;
            height: 150px;
            cursor: pointer;
            border-style: groove;
            border-top: 7px solid #003c69 !important;
            border-radius: 0;

            .header-image {
                position: relative;
                height: 175px;
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            .icon {
                width: 20px;
                color: #13b5ea;
                position: absolute;
                bottom: 2px;
                right: 14px;
                font-size: 30px;
            }

            .icon-calendar {
                width: 20px;
                color: grey;
                position: relative;
                padding-right: 2px;
                padding-left: 10px;
                font-size: 20px;
            }

            .icon-location {
                width: 20px;
                color: grey;
                position: relative;
                padding-right: 2px;
                padding-left: 10px;
                font-size: 20px;
            }

            // example of container classes: bookmark insight-video
            .text {
                padding: 20px;
                height: 205px;
                font-family: 'nunito-sans';

                h2 {
                    font-size: 1.05rem;
                    letter-spacing: 0.07rem;
                    letter-spacing: 0;
                    color: #465661;
                    overflow: visible;
                    text-overflow: ellipsis;
                    margin-bottom: 12px;
                }

                p {
                    font-size: 0.9rem;
                    color: #465661;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .text:hover,
            .text.active {
                text-decoration-color: $blue;
            }

            .feature-Date {
                white-space: nowrap;
                color: #a7a8a9;
                padding: 20px;
                padding-bottom: 2%;
            }
        }

        .subscribe-Note {
            // padding-left: 20%;
            color: $krollgreen;
            // font-style: italic;
            padding-top: 7px;
            text-align: center;

        }

        .cocUS {
            border-style: groove;
            border-top: 7px solid #43b049 !important;
        }

        .indUS {
            border-style: groove;
            border-top: 7px solid #c4d600 !important;
        }

        .cocIntl {
            border-style: groove;
            border-top: 7px solid #14487f !important;
        }

        .indIntl {
            border-style: groove;
            border-top: 7px solid #67d2df !important;
        }

        .beta {
            border-style: groove;
            border-top: 7px solid #df6d67 !important;
        }

        .available {
            border-style: groove;
            border-top: 7px solid #36b4ea !important;
        }

        h3 {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    .explore {
        text-align: right;
        cursor: pointer;

        h2 {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: normal;
        }

        .chevron {
            width: 8px;
            position: relative;
            top: -2px;
            margin-left: 10px;
        }
    }

    .insights-gray {
        padding: 0px 30px;
        background-color: $thinlightgray;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -30px;
    }

    .insights-white {
        padding: 0px 30px;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -30px;
    }

    .insights-content {
        padding: 0px 30px;
    }

    .insights-gray h1 {
        font-size: 1.5rem;
        padding-top: 30px;
        padding-bottom: 30px;
        font-family: 'nunito-sans';
        color: $krollblue;
        font-weight: bold;
    }

    .insights-white h1 {
        font-size: 1.5rem;
        padding-top: 30px;
        padding-bottom: 30px;
        font-family: 'nunito-sans';
    }

    .insights {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 1200px;

        a {
            text-decoration: none;
        }
    }

    .insights .insight-wrapper {
        width: 23.20819%;
        height: 360px;
        margin: 0 10px 40px 0px;
    }

    .insights .insight-wrapper .insight {
        position: relative;
        background: white;
        width: 100%;
        height: 360px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .insights .insight-wrapper .insight .header-image {
        position: relative;
        height: 175px;
    }

    .insights .insight-wrapper .insight .header-image img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .insights .insight-wrapper .insight .text {
        padding: 20px;
        height: 205px;
        font-family: 'nunito-sans';
    }

    .insights .insight-wrapper .insight .text h2 {
        font-size: 0.9rem;
        font-family: 'nunito-sans';
        letter-spacing: 0;
        color: #465661;
        overflow: visible;
        text-overflow: ellipsis;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    .insights .insight-wrapper .insight .text p {
        font-size: 0.9rem;
        color: #465661;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .hover-box:hover {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
        -ms-transform: translateY(-6px) !important;
        transform: translateY(-6px) !important;
    }

    .hover-box:active {
        -ms-transform: translateY(-3px) !important;
        transform: translateY(-3px) !important;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    }

    .betaExpieryFont {
        font-size: 1rem;
        font-weight: 400;
    }
}