@import "./colors.scss";

button {
    cursor: pointer;
}
.italics {
    font-style: italic;
}
.bold {
    font-weight: bold;
}
.success {
    color: $success;
}
.error {
    color: $error;
}

