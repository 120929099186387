.information-line {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
  margin:0 15px
}
.information-panel {
    margin-top: 30px;
    margin-right: -51px;
    margin-left: 53px;
    background-color: #e9ecee;
}
.information-line-wrapper {
  padding: 10px 15px;
  flex-direction: row;
  display: flex;
  margin-left: 10px;
}
.information-line-data {
  margin-left: 60px;
}
.information-label {
  text-align: left;
  color: $krollblue;
  font-size: 20px;
  font-weight: bold;
  margin: 20px 10px;
  padding-left: 10px;
  padding-top: 5px;
}
.country-table {
  display: contents;
}
